import {
  generateCountryGroupsFilter,
  generateLocationGroupsFilter,
} from '~/slices/LisaContent/adapter.new';
import { productFull } from '~/utils/adapters/product';
import { coverTypeLinks } from '~/utils/fetch-links';
import { useI18n } from '#imports';

const FIELD_NAME = {
  location: 'products-location',
  coverType: 'products-cover-type',
  language: 'products-language',
  destination: 'products-destination',
};

export default {
  data() {
    return {
      selectedCoverType: null,
      selectedDestination: null,
      selectedLanguage: null,
      selectedLocation: null,
      filters: null,
      cards: [],
    };
  },
  setup: (props) => {
    const i18n = useI18n();

    return { i18n };
  },
  methods: {
    parseCards(cards, i18n, enhancedLinkSerializer) {
      const cardsList = cards.map((result) => ({
        featured_card: { ...result, link_type: 'Document' },
      }));

      return cardsList.map(({ featured_card: featuredCard }) => {
        if (!featuredCard) {
          return null;
        }
        return {
          type: 'product-card',
          data: productFull(featuredCard, i18n, enhancedLinkSerializer),
        };
      });
    },
    async onFiltersInput(event) {
      if (event.name === FIELD_NAME.location) {
        this.selectedLocation = event.value;
      }
      if (event.name === FIELD_NAME.coverType) {
        this.selectedCoverType = event.value;
      }
      if (event.name === FIELD_NAME.language) {
        this.selectedLanguage = event.value;
      }
      if (event.name === FIELD_NAME.destination) {
        this.selectedDestination = event.value;
      }
      const results = await this.filterProducts();

      this.cards = this.parseCards(
        results,
        this.i18n,
        this.$enhancedLinkSerializer,
      );
      return this.cards;
    },
    async generateQueries(prismic, lang, isPro) {
      const documentFilter = prismic.filter.at('document.type', 'product_page');

      const isProFilter = isPro
        ? prismic.filter.at('document.tags', ['pro'])
        : prismic.filter.not('document.tags', ['pro']);

      const coverTypeFilter = this.selectedCoverType
        ? prismic.filter.any(`my.product_page.cover_types.cover_type`, [
            this.selectedCoverType.value,
          ])
        : null;

      const languageFilter = this.selectedLanguage
        ? prismic.filter.any(`my.product_page.languages.language`, [
            this.selectedLanguage.value,
          ])
        : null;

      const destinationGroupsFilter = this.selectedDestination
        ? await generateCountryGroupsFilter(
            prismic.client,
            lang,
            this.selectedDestination.value,
          )
        : null;
      const destinationsFilter = this.selectedDestination
        ? prismic.filter.at(
            `my.product_page.destination_countries.destination_country`,
            this.selectedDestination.value,
          )
        : null;

      const excludedDestinationsFilter = this.selectedDestination
        ? prismic.filter.not(
            `my.product_page.excluded_destinations.excluded_destination`,
            this.selectedDestination.value,
          )
        : null;

      const locationGroupsFilter = this.selectedLocation
        ? await generateLocationGroupsFilter(
            prismic.client,
            lang,
            this.selectedLocation.value,
          )
        : null;

      const locationsFilter = this.selectedLocation
        ? prismic.filter.at(
            `my.product_page.locations.location`,
            this.selectedLocation.value,
          )
        : null;

      const excludedLocationsFilter = this.selectedLocation
        ? prismic.filter.not(
            `my.product_page.excluded_locations.excluded_location`,
            this.selectedLocation.value,
          )
        : null;

      const excludeFakeUSAProduct = prismic.filter.not(
        `my.product_page.uid`,
        'usamyhealthinternational',
      );

      const query = [
        documentFilter,
        coverTypeFilter,
        isProFilter,
        languageFilter,
        excludedDestinationsFilter,
        excludedLocationsFilter,
        excludeFakeUSAProduct,
      ].filter((item) => item);

      const queriesList = [
        locationGroupsFilter && destinationGroupsFilter
          ? [...query, destinationGroupsFilter, locationGroupsFilter]
          : null,
        destinationGroupsFilter
          ? [...query, destinationGroupsFilter, locationsFilter].filter(
              (item) => item,
            )
          : null,
        locationGroupsFilter
          ? [...query, destinationsFilter, locationGroupsFilter].filter(
              (item) => item,
            )
          : null,
        [...query, destinationsFilter, locationsFilter].filter((item) => item),
      ].filter((item) => item);
      return queriesList;
    },
    async filterProducts() {
      const lang = this.i18n.localeProperties.value.language;
      try {
        const queries = await this.generateQueries(
          this.$prismic,
          lang,
          this.context?.isPro,
        );

        let results = [];
        for (const queryFilter of queries) {
          try {
            const result = await this.prismic.get({
              filters: queryFilter,
              page: 1,
              pageSize: 100,
              lang,
              fetchLinks: [...coverTypeLinks],
            });
            if (result.results.length > 0) {
              results.push(result.results);
            }
            results.push([]);
          } catch (e) {
            results = [];
            console.error({ e });
          }
        }
        results = results.flat(2);
        const resultsWithoutDuplicates = this.removeDuplicates(results);
        const prioritizedProduct = this.getPrioritizedProduct(
          resultsWithoutDuplicates,
        );

        return prioritizedProduct;
      } catch (e) {
        console.error({ e });
      }
    },
    removeDuplicates(list) {
      const uniqueList = Object.values(
        list.reduce((uniqueProduct, obj) => {
          const id = obj.id;

          if (!uniqueProduct[id]) {
            uniqueProduct[id] = obj;
          }

          return uniqueProduct;
        }, {}),
      );

      return uniqueList;
    },
    getPrioritizedProduct(productsFound) {
      const hasTwoProducts = productsFound.length === 2;
      if (hasTwoProducts) {
        const localProduct = productsFound.find(
          (product) => !product.data.global_offer,
        );
        if (localProduct) {
          return [localProduct];
        }
      }

      return productsFound;
    },
  },
};
