import * as prismicClient from '@prismicio/client';
import { stripRichTextWrapperTag } from '@/utils/html';
import {
  routerLinks,
  offerLinks,
  countryLinks,
  regionLinks,
} from '@/utils/fetch-links';
import {
  getType,
  getCardType,
  cardCountryAdapter,
  cardRegionAdapter,
  cardGuideAdapter,
  cardArticleAdapter,
} from '@/utils/helpers/get-cards';

export const cardsAdapter = (cards, i18n, enhancedLinkSerializer) => {
  return cards.map(({ edito_carousel_card: editoCarouselCard }) => {
    const cardType = getCardType(editoCarouselCard.type);
    const adatpterData = {
      editoCarouselCard,
      cardType,
      i18n,
      enhancedLinkSerializer,
    };

    switch (cardType) {
      case 'guide':
        return cardGuideAdapter(adatpterData);
      case 'region':
        return cardRegionAdapter(adatpterData);
      case 'country':
        return cardCountryAdapter(adatpterData);
      case 'article':
      default:
        return cardArticleAdapter(adatpterData);
    }
  });
};

const getOrdering = (order, queryType) => {
  switch (order) {
    case 'DESC date':
      return [
        { field: `my.${queryType}.date`, direction: 'desc' },
        { field: 'document.last_publication_date', direction: 'desc' },
      ];
    case 'ASC title':
    default:
      return [{ field: `my.${queryType}.title`, direction: 'asc' }];
  }
};
const getItems = async (
  { pageId, lang, page, type, offer, cardsPerPage, orderBy },
  prismic,
  prismicPlugin,
) => {
  const queryType = getType(type);
  const order = getOrdering(orderBy, queryType);
  const query = [prismicClient.filter.at('document.type', queryType)];

  if (offer?.id) {
    query.push(prismicClient.filter.at(`my.${queryType}.offer`, offer.id));
  }

  if (queryType === 'page_country') {
    query.push(prismicClient.filter.at('my.page_country.region', pageId));
  }

  const prismicParams = {
    filters: query,
    orderings: order,
    pageSize: cardsPerPage,
    page,
    fetchLinks: [
      ...routerLinks,
      ...offerLinks,
      ...countryLinks,
      ...regionLinks,
    ],
    lang,
  };

  const items = await prismic.get(prismicParams);

  return {
    totalPages: items.total_pages,
    cards: items.results.map((result) => ({
      edito_carousel_card: { ...result, link_type: 'Document' },
    })),
  };
};

export default async (
  { primary },
  { pageId, page, i18n, prismic, enhancedLinkSerializer, prismicPlugin },
) => {
  const {
    title,
    offer,
    with_pagination: withPagination,
    cards_per_page: cardsPerPage,
    order_by: orderBy,
    type,
  } = primary || {};

  const lang = i18n.localeProperties.value.language;

  const cardItems = await getItems(
    { pageId, lang, page, type, offer, withPagination, cardsPerPage, orderBy },
    prismic,
    prismicPlugin,
  );
  const { cards, totalPages } = cardItems || {};

  return {
    title: title && stripRichTextWrapperTag(title),
    withPagination,
    totalPages,
    withCarousel: primary.edito_carousel_active,
    cards: cardsAdapter(cards, i18n, enhancedLinkSerializer),
  };
};
