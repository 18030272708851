import { richText } from '@/utils/html';

export default (data, isInline) => {
  if (!data) {
    return null;
  }

  const contact = {
    mention: data.contact_mention,
  };

  if (isInline) {
    contact.info = data.contact_inline_description;
  } else {
    contact.subhead = data.contact_subhead;
    contact.title = data.contact_title;
    contact.info = data.contact_description
      ? richText(data.contact_description)
      : '';
  }

  if (data.contact_phone_number || data.contact_phone_label) {
    contact.number = {
      label: data.contact_phone_label || data.contact_phone_number,
      tel: data.contact_phone_number,
    };
  }

  return contact;
};
