import { stripRichTextWrapperTag } from '../../utils/html';

export default ({ primary, items }, { enhancedLinkSerializer }) => ({
  title: stripRichTextWrapperTag(primary.documents_list_title),
  docs: items
    .filter(
      ({ document_title: documentTitle, document_file: documentFile }) =>
        documentTitle && documentFile && documentFile.url,
    )
    .map((item) => {
      return {
        title: stripRichTextWrapperTag(item.document_title),
        link: enhancedLinkSerializer(item.document_file),
      };
    }),
});
