<template>
  <section class="section">
    <PrismicRichText :field="slice.primary.title" class="title" />
    <PrismicRichText :field="slice.primary.description" />
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue';

export default {
  name: 'EditoTableRow',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
};
</script>

<style scoped>
.section {
  background: #f7f7f7;
  color: #111;
  padding: 4em;
  text-align: center;
}
.title {
  margin-bottom: 2em;
}
</style>
