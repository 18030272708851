import { stripRichTextWrapperTag, richText } from '@/utils/html';
import { routerLinks } from '@/utils/fetch-links';

const getContact = (data, isInline) => {
  if (!data) {
    return null;
  }

  const contact = {
    mention: data.contact_mention,
    phoneOnly: data.phone_only,
  };

  if (isInline) {
    contact.info = data.contact_inline_description;
  } else {
    contact.subhead = data.contact_subhead;
    contact.title = data.contact_title;
    contact.info = richText(data.contact_description);
  }

  if (data.contact_phone_number || data.contact_phone_label) {
    contact.number = {
      label: data.contact_phone_label || data.contact_phone_number,
      tel: data.contact_phone_number,
    };
  }
  return contact;
};

const adapter = async (
  { primary, items },
  { prismic, i18n, enhancedLinkSerializer, borders = false },
) => {
  const link = enhancedLinkSerializer(primary.contact_push_link);

  const contacts = [];
  for (const item of items) {
    const { contact } = item;
    const contactModule = await prismic.getByID(contact?.id, {
      fetchLinks: routerLinks,
      lang: i18n.localeProperties.value.language,
    });

    if (contact && contactModule && contactModule.data) {
      contacts.push(getContact(contactModule.data));
    }
  }

  return {
    title: stripRichTextWrapperTag(primary.contact_push_title),
    cta: link
      ? {
          label: i18n.t('cta.see-all'),
          ...link,
        }
      : null,
    contactsItem: contacts.filter((contact) => contact !== null),
    borders,
  };
};

export default adapter;
