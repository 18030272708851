import * as prismic from '@prismicio/client';
import { cardsAdapter } from '~/slices/CardsList/adapter';
import {
  guideLinks,
  offerLinks,
  pageLinks,
  routerLinks,
} from '~/utils/fetch-links';
import { stripRichTextWrapperTag } from '~/utils/html';

const editoCarouselAllTaxonomyAdapter = async (
  slice,
  prismic,
  i18n,
  enhancedLinkSerializer,
) => {
  const { primary, items } = slice;
  const taxonomies = items.map((item) => item.taxonomy.id);
  const guideCards = await getGuidesByTaxonomy(
    taxonomies,
    prismic,
    false,
    i18n.localeProperties.value.language,
  );

  return {
    head: {
      title: stripRichTextWrapperTag(primary.title),
    },
    featured: true,
    withCarousel: false,
    cards: cardsAdapter(guideCards, i18n, enhancedLinkSerializer),
  };
};

const getGuidesByTaxonomy = async (taxonomies, $prismic, isPro, lang) => {
  const result = await fetchPagedGuidesByTaxonomy(
    taxonomies,
    $prismic,
    isPro,
    lang,
    1,
  );
  return result.map((result) => ({
    edito_carousel_card: { ...result, link_type: 'Document' },
  }));
};

const fetchPagedGuidesByTaxonomy = async (
  taxonomies,
  $prismic,
  isPro,
  lang,
  page = 1,
) => {
  const finalResult = [];
  const res = await $prismic.get({
    filters: [
      prismic.filter.at('document.type', 'page_guide'),
      prismic.filter.any('my.page_guide.taxonomies.taxonomy', taxonomies),
      isPro
        ? prismic.filter.at('document.tags', ['pro'])
        : prismic.filter.not('document.tags', ['pro']),
    ],

    orderings: `[my.page_guide.date desc, document.last_publication_date desc]`,
    page,
    fetchLinks: [...routerLinks, ...guideLinks, ...offerLinks, ...pageLinks],
    pageSize: 100,
    lang,
  });
  finalResult.push(...res.results);
  if (res.next_page) {
    finalResult.push(
      ...(await fetchPagedGuidesByTaxonomy(
        $prismic,
        taxonomies,
        isPro,
        lang,
        page + 1,
      )),
    );
  }

  return finalResult.flat(Infinity);
};

export default editoCarouselAllTaxonomyAdapter;
