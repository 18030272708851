<template>
  <organisms-advantages-push v-if="sliceData" v-bind="sliceData" />
</template>

<script setup>
import { getSliceComponentProps } from '@prismicio/vue';
import advantagesPushAdapter from '@/slices/AdvantagesPush/adapter';
import { useI18n, usePrismic, useNuxtApp, useAsyncData } from '#imports';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const i18n = useI18n();
const { client: $prismic } = usePrismic();
const { $enhancedLinkSerializer } = useNuxtApp();

const { data: sliceData } = await useAsyncData(
  `advantagesPushData-${props.slice.id}`,
  async () => {
    return await advantagesPushAdapter(props.slice, {
      prismic: $prismic,
      enhancedLinkSerializer: $enhancedLinkSerializer,
      i18n,
    });
  },
);
</script>
