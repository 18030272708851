<template>
  <organisms-featured-block v-if="sliceData" v-bind="sliceData">
    <template #actions>
      <atoms-cta-label
        v-for="(action, i) in sliceData.actions"
        :key="i"
        v-bind="action.link"
      >
        {{ action.label }}
      </atoms-cta-label>
    </template>
  </organisms-featured-block>
</template>

<script setup>
import { getSliceComponentProps } from '@prismicio/vue';
import featuredBlockAdapter, {
  withTabsAdapter as withTabsFeaturedBlockAdapter,
} from '@/slices/FeaturedBlock/adapter';
import { useI18n, usePrismic, useNuxtApp, useAsyncData } from '#imports';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const i18n = useI18n();
const { client: $prismic } = usePrismic();
const { $enhancedLinkSerializer } = useNuxtApp();

const { data: sliceData } = await useAsyncData(
  `featuredBlockData-${props.slice.id}-${i18n.localeProperties.value.language}`,
  async () => {
    const adapter =
      props.slice.variation === 'withTabs'
        ? withTabsFeaturedBlockAdapter
        : featuredBlockAdapter;

    return await adapter(props.slice, {
      i18n,
      prismic: $prismic,
      enhancedLinkSerializer: $enhancedLinkSerializer,
    });
  },
);
</script>
