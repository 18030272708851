import { confirmationAdapter } from '../adapter';
import { getInputs } from './inputs';
import { routingLabelsToKeys } from './routing';
import { richText, stripRichTextWrapperTag } from '@/utils/html';

export default (formData, { lang, translations, enhancedLinkSerializer }) => {
  const inputs = getInputs({ lang, translations });
  let routing;

  const inputsData = formData.form_fields
    .filter(({ form_field: formField }) => inputs[formField])
    .map(({ form_field: formField, routing_rule: routingRule, required }) => {
      const input = inputs[formField];

      if (routingRule) {
        routing = routingLabelsToKeys[routingRule];
        input.field.id = 'routingInput';
      }

      if (input.field.type === 'double-input') {
        input.field.inputs.forEach((fieldInput) => {
          fieldInput.required = required;
        });
      }

      return { ...input, required };
    });

  const confirmationData = confirmationAdapter(
    formData,
    enhancedLinkSerializer,
  );

  return {
    routing,
    applicant: formData.applicant,
    templateId: formData.mailjet_template_id,
    recipients: formData.recipients.map(({ recipient }) => recipient),
    data: {
      title: stripRichTextWrapperTag(formData.title),
      intro: richText(formData.description),
      inputs: inputsData,
      mention: richText(formData.legal_mention),
      buttons: [
        ...formData.form_actions.map(
          ({ action_label: actionLabel, action_link: actionLink }) => ({
            label: actionLabel,
            cta: {
              ...enhancedLinkSerializer(actionLink),
              variant: 'secondary',
            },
          }),
        ),
        {
          label: formData.submit_label,
          cta: { type: 'submit' },
        },
      ],
    },
    confirmationData,
  };
};
