<template>
  <section v-if="sliceData">
    <organisms-article-head v-bind="sliceData.head" />
    <organisms-edito-carousel v-bind="sliceData.items" />
    <molecules-ui-pagination :pages="sliceData.items.totalPages" />
  </section>
</template>

<script setup>
import { getSliceComponentProps } from '@prismicio/vue';
import allGuidesAdapter from '@/slices/Guides/adapter';
import {
  useI18n,
  usePrismic,
  useNuxtApp,
  useAsyncData,
  useRoute,
} from '#imports';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const i18n = useI18n();
const route = useRoute();
const { client: $prismic } = usePrismic();
const { $enhancedLinkSerializer } = useNuxtApp();

const { data: sliceData } = await useAsyncData(
  `guides-${props.slice.id}`,
  async () => {
    const page = parseInt(route.query.page) || 1;
    return await allGuidesAdapter(props.slice, {
      page,
      pageData: props.context,
      i18n,
      prismic: $prismic,
      enhancedLinkSerializer: $enhancedLinkSerializer,
    });
  },
  {
    watch: [() => route.query.page],
  },
);
</script>
