import { richText, stripRichTextWrapperTag } from '../../utils/html';

export default ({ primary }, { i18n }) => {
  const date = primary.date ? new Date(primary.date) : null;
  const formattedDate = date
    ? new Intl.DateTimeFormat(i18n.locale.value).format(date)
    : null;

  return {
    date: formattedDate
      ? `${i18n.t('article.published', { date: formattedDate })}`
      : null,
    readingTime: primary.reading_time
      ? `${i18n.t('article.reading-time', {
          readingTime: primary.reading_time,
        })}`
      : null,
    title: stripRichTextWrapperTag(primary.title),
    description: primary.description ? richText(primary.description) : null,
  };
};
