<template>
  <div v-if="isNewLisa">
    <div v-if="lisaModule.image">
      <organisms-lisa-module-panel
        v-bind="lisaModule"
        ref="lisa"
        :lisa-module="{ ...lisaModule, title: null }"
        :with-wrapper="false"
        class="lisa-panel"
        :on-lisa-input="onLisaModuleInput"
        :on-lisa-submit="onLisaModuleSubmit"
        :on-lisa-quote="onLisaModuleQuote"
      />
    </div>
  </div>
  <div v-else>
    <lisa-panel ref="lisaPanel" :uid="computedLisaUID" />
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { ref, watch, computed } from 'vue';
import { useI18n, usePrismic, useAsyncData } from '#imports';
import useLisaModule from '@/composables/lisaModule';

export default {
  name: 'LisaModulePanel',
  props: {
    lisaUid: {
      type: String,
      default: null,
    },
  },
  setup: async (props) => {
    const isNewLisa = ref(false);
    const store = useStore();
    const i18n = useI18n();
    const { client: $prismic } = usePrismic();

    const computedLisaUID = computed(() => props.lisaUid || 'part-lisa-common');
    const locale = computed(() => i18n.localeProperties.value.language);

    const {
      onLisaModuleSubmit,
      onLisaModuleQuote,
      onLisaModuleInput,
      initLisaModule,
      lisaModule,
      autoFillLisaOptions,
    } = useLisaModule({ i18n });

    const initLisa = async () => {
      if (isNewLisa.value) {
        await initLisaModule({
          useLisaTitle: false,
          resetButton: false,
          type: 'panel',
          uid: computedLisaUID.value,
        });
      }
    };

    const fetchLisaData = async () => {
      const lisa = await $prismic.getByUID(
        'module_lisa',
        computedLisaUID.value,
        {
          lang: i18n.localeProperties.value.language,
        },
      );
      isNewLisa.value = lisa.data.new_lisa;
    };

    await useAsyncData(`lisaModulePanel-${props.lisaUid}`, async () => {
      await fetchLisaData();
      initLisa();

      return null;
    });

    const pingAutoFill = () => {
      const fields = store.state.layout.lisaAutofilled;
      const reset = true;
      lisaModule.value = autoFillLisaOptions(lisaModule.value, fields, reset);
    };

    watch(locale, async () => {
      await fetchLisaData();
      initLisa();
    });

    watch(computedLisaUID, async () => {
      await fetchLisaData();
      initLisa();
    });

    return {
      pingAutoFill,
      isNewLisa,
      lisaModule,
      onLisaModuleSubmit,
      onLisaModuleQuote,
      onLisaModuleInput,
      computedLisaUID,
    };
  },
};
</script>
