<template>
  <organisms-accordion-push v-bind="sliceData" />
</template>

<script>
import { ref } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import getJSONLD from '@/utils/structured-data';
import accordionPushAdapter from '@/slices/AccordionPush/adapter';
import { useHead } from '#imports';

export default {
  name: 'AccordionPush',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup: (props) => {
    const sliceData = ref(null);

    sliceData.value = accordionPushAdapter(props.slice);

    useHead({
      script: [getJSONLD('faq', sliceData)],
    });

    return { sliceData };
  },
};
</script>
