<template>
  <organisms-edito-carousel v-if="sliceData" v-bind="sliceData" />
</template>

<script setup>
import { getSliceComponentProps } from '@prismicio/vue';
import editoCarouselAdapter from '@/slices/EditoCarousel/adapter';
import editoCarouselTaxonomyAdapter from '@/slices/EditoCarousel/taxonomyAdapter';
import editoCarouselAllTaxonomyAdapter from '@/slices/EditoCarousel/allTaxonomyAdapter';
import { useI18n, usePrismic, useNuxtApp, useAsyncData } from '#imports';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const i18n = useI18n();
const { client: $prismic } = usePrismic();
const { $enhancedLinkSerializer } = useNuxtApp();

const { data: sliceData } = await useAsyncData(
  `editoCarousel-${props.slice.id}`,
  async () => {
    const isTaxonomyVariation = props.slice.variation === 'taxonomies';
    const listAllGuides = props.slice.primary?.list_all;

    if (isTaxonomyVariation && listAllGuides) {
      return await editoCarouselAllTaxonomyAdapter(
        props.slice,
        $prismic,
        i18n,
        $enhancedLinkSerializer,
      );
    }

    const adapter = isTaxonomyVariation
      ? editoCarouselTaxonomyAdapter
      : editoCarouselAdapter;

    const data = await adapter(props.slice, {
      i18n,
      prismic: $prismic,
      enhancedLinkSerializer: $enhancedLinkSerializer,
    });

    data.cards = data.cards.length
      ? data.cards.filter((card) => card.title !== props.context.title[0].text)
      : [];

    return data;
  },
);
</script>
