import { useGtm } from '@gtm-support/vue-gtm';
import { stripHTMLTags } from '@/utils/html';
import { useNuxtApp } from '#imports';
import { defineNuxtPlugin } from '#app';

const fallbackString = '-';

const getContext = (_) => {
  const context = useNuxtApp();
  const store = context.store || context.$store;
  const i18n = context.app?.i18n || context.$i18n;
  const $gtm = useGtm();

  return { store, $gtm, i18n };
};

export const pushCtaClick = (
  context,
  { label, module, lien_cta: lienCta, euroAmount = null },
  el,
) => {
  const { $gtm, store, i18n } = getContext(context);

  let eventLabel = label;

  if (module === 'faq-push' && el.getAttribute('aria-expanded') !== 'true') {
    return;
  } else if (module === 'product-card') {
    eventLabel = `${label} ${i18n.t('tracking.product-event-label')}`;
  }

  const { market, product, pageType } = store.state.tracking.data;
  const categoryMarket = market ? ` ${market}` : '';

  const data = {
    event: 'evenement_web',
    event_type: 'action_internaute',
    event_action: 'Clic CTA',
    event_libelle: eventLabel,
    marche: market || fallbackString,
    produit: product || fallbackString,
    page_type: pageType || fallbackString,
    event_categorie: `${pageType || fallbackString}${categoryMarket}`,
    nom_module: module,
    page_virtuelle: null,
  };

  // Directed to external links
  if (lienCta) data.clic_url = lienCta;

  if (euroAmount) data.montant_total_prets = euroAmount;

  $gtm.push(data);
};

export const pushPageView = (context) => {
  const { $gtm, store } = getContext(context) || {};
  const { offer, product, pageType } = store.state?.tracking?.data || {};

  const pageViewData = {
    event: 'evenement_web',
    event_type: 'page_vue',
    marche: offer || fallbackString,
    produit: product || fallbackString,
    page_type: pageType || fallbackString,
    page_virtuelle: fallbackString,
  };

  $gtm.push(pageViewData);
};

export const pushLisaForm = (context, values) => {
  const { $gtm, store } = getContext(context);
  const { offer, product, pageType } = store.state?.tracking?.data || {};
  const {
    lisaPaysResidence,
    lisaPaysDestination,
    lisaBesoin,
    lisaPaysNationality,
    resultType,
  } = values || {};

  const submitData = {
    event: 'evenement_web',
    event_type: 'recherche_lisa',
    marche: offer || fallbackString,
    produit: product || fallbackString,
    page_type: pageType || fallbackString,
    page_virtuelle: fallbackString,
    lisa_pays_residence: lisaPaysResidence || fallbackString,
    lisa_pays_destination: lisaPaysDestination || fallbackString,
    lisa_besoin: lisaBesoin || fallbackString,
    lisa_nationalite: lisaPaysNationality || fallbackString,
    type_resultat: resultType || fallbackString,
  };

  $gtm.push(submitData);
};

export const pushLisaModule = (context, values) => {
  const { $gtm, store } = getContext(context);
  const { offer, product, pageType } = store.state?.tracking?.data || {};
  const { destination, coverType, language, location, resultType } =
    values || {};
  const destinationISO = destination.value.split(' ')[0];
  const locationISO = location.value.split(' ')[0];
  const submitData = {
    event: 'evenement_web',
    event_type: 'recherche_lisa',
    marche: offer || fallbackString,
    produit: product || fallbackString,
    page_type: pageType || fallbackString,
    page_virtuelle: fallbackString,
    lisa_pays_residence: locationISO || fallbackString,
    lisa_pays_destination: destinationISO || fallbackString,
    lisa_besoin: coverType.label || fallbackString,
    lisa_langue_communication: language.label || fallbackString,
    lisa_nationalite: fallbackString,
    type_resultat: resultType || fallbackString,
  };

  $gtm.push(submitData);
};

export const pushFormSubmit = (context, values) => {
  const { $gtm, store } = getContext(context);
  const { offer, product, pageType } = store.state?.tracking?.data || {};
  const { formName, mailObject } = values || {};

  const submitData = {
    event: 'evenement_web',
    event_type: 'form_confirme',
    marche: offer || fallbackString,
    produit: product || fallbackString,
    page_type: pageType || fallbackString,
    page_virtuelle: fallbackString,
    nom_formulaire: stripHTMLTags(formName) || fallbackString,
    objet_contact: mailObject || fallbackString,
  };

  $gtm.push(submitData);
};

export default defineNuxtPlugin((nuxtApp) => {
  const gtm = useGtm();

  gtm.enable();
  pushPageView(nuxtApp);

  let handleEvent;

  nuxtApp.vueApp.directive('track', {
    mounted: (el, { value, arg }) => {
      handleEvent = () => {
        value && pushCtaClick(nuxtApp, value, el);
      };

      arg && el.addEventListener(arg, handleEvent);
    },
    unmounted: (el, { arg }) => {
      arg && el.removeEventListener(arg, handleEvent);
    },
  });
});
