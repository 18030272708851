import * as prismic from '@prismicio/client';

export default async (page, $prismic) => {
  const alternateIds = page.data._value.alternate_languages.map(
    (lang) => lang.id,
  );
  const altLangs = await $prismic.get({
    filters: [prismic.filter.in('document.id', alternateIds)],
    lang: '*',
  });
  return altLangs;
};
