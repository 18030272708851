import { ref, onBeforeMount } from 'vue';
import { useI18n } from '#imports';

export function useTranslations() {
  const translations = ref({});
  const i18n = useI18n();

  const fetchTranslations = async (module) => {
    const locale = i18n.locale.value;
    try {
      const response = await fetch(
        `/locales/${locale}/${locale}.${module}.json`,
      );
      const moduleJsonTranslations = await response.json();
      translations.value = moduleJsonTranslations || {};
      i18n.mergeLocaleMessage(locale, translations.value);
    } catch (err) {
      throw new Error(`No ${locale} language translation found for ${module}`);
    }
  };

  onBeforeMount(() => {
    // prevent reverting back to not found after hard-loading page.
    i18n.mergeLocaleMessage(i18n.locale, translations.value);
  });

  return {
    translations,
    fetchTranslations,
  };
}
