<template>
  <organisms-quote-push v-if="sliceData" v-bind="sliceData" />
</template>

<script>
import { ref } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import quoteAdapter from '@/slices/Quote/adapter';

export default {
  name: 'Quote',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup: (props) => {
    const sliceData = ref(null);

    sliceData.value = quoteAdapter(props.slice);

    return { sliceData };
  },
};
</script>
