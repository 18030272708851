import linkResolver from './link-resolver';

export default (link, isPro, isDocument, store) => {
  const redirections = store?.state?.redirections || [];

  if (!link) {
    process.env.NODE_ENV !== 'production' &&
      // eslint-disable-next-line no-console
      console.warn(
        "[DEV only warning] Missing Link. Please check you didn't mispell a field name",
      );
    return null;
  }

  if (!link.tags) {
    link.tags = [];
  }

  const isDataModule = link?.type?.includes('module_');

  link.sitePro = isPro;

  if (!isDataModule && (link.link_type === 'Document' || isDocument)) {
    const redirection = redirections.find(
      (redirectionLink) => redirectionLink.from === linkResolver(link),
    );

    const isExternalRedirection = redirection?.to?.includes('https')
      ? 'true'
      : 'false';

    return redirection
      ? {
          href: redirection.to,
          external: isExternalRedirection,
        }
      : { field: { ...link } };
  } else if (link.link_type === 'Web') {
    const href = link.url;
    return {
      href,
      external: link.target === '_blank' ? 'blank' : undefined,
    };
  } else if (
    link.type === 'application/pdf' ||
    link.type === 'application/postscript' ||
    link.type === 'application/zip' ||
    link.type?.includes('image')
  ) {
    return {
      href: link.url,
      external: 'blank',
    };
  } else if (
    link.link_type === 'Any' ||
    link.link_type === null ||
    link.link_type === undefined
  ) {
    return null;
  }
};
