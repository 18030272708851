import { richText } from '@/utils/html';

export default (data, { $enhancedLinkSerializer, i18n }) => ({
  headLinks: [
    {
      type: 'list',
      links: data.quick_access_links.map(({ label, link }) => ({
        label,
        link: $enhancedLinkSerializer(link),
      })),
    },
    {
      type: 'social',
      networks: {
        title: data.social_networks_title,
        links: data.social_networks_links.map(({ name, link }) => ({
          name,
          link: $enhancedLinkSerializer(link),
        })),
      },
      stores: {
        title: data.app_stores_title,
        links: data.app_stores_links.map(({ name, logo, link }) => ({
          name,
          logo: logo?.url,
          link: $enhancedLinkSerializer(link),
        })),
      },
    },
    {
      type: 'accordion',
      title: data.quotes_title,
      links: data.quotes_links.map(({ label, link }) => ({
        label,
        link: $enhancedLinkSerializer(link),
      })),
    },
    {
      type: 'accordion',
      title: data.about_title,
      links: data.about_links.map(({ label, link }) => ({
        label,
        link: $enhancedLinkSerializer(link),
      })),
    },
  ],
  legalLinks: {
    title: data.legal_title,
    links: data.legal_links.map(
      ({ label, link, cookies_opener: cookiesOpener }) => ({
        label,
        link: $enhancedLinkSerializer(link),
        cookiesOpener,
      }),
    ),
  },
  legalText: richText(data.legal_text),
  footLinks: {
    title: data.markets_title,
    links: data.markets_links.map(({ label, link }) => ({
      label,
      link: $enhancedLinkSerializer(link),
    })),
  },
  languagesSwitcher: {
    label: `${i18n.t('global.language-switcher')} :`,
    languages: data.languages,
  },
  logo: 'international-monochrome',
  seo: {
    organization_logo: data.organization_logo?.url,
    social_profiles: data.social_networks_links.map(({ link }) => link.url),
  },
});
