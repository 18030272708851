import { richText } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';

export default ({ primary }) => {
  const image = imageAdapter(primary.img, primary.image_alt);
  return {
    img: { ...image, ...image.size },
    caption: primary.caption && richText(primary.caption),
    narrow: !primary.is_full_width,
  };
};
