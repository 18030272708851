<template>
  <organisms-form-lisa
    ref="lisa"
    v-bind="heroLisaData ? heroLisaData : heroLisaDefaultData"
    @form-lisa-submit="onFormLisaSubmit"
    @form-lisa-input="onFormLisaInput"
  />
</template>

<script>
import lisaContentAdapter from '@/slices/LisaContent/adapter';
import { useI18n, usePrismic, useAsyncData } from '#imports';
import useLisa from '@/composables/lisa';

export default {
  name: 'Hero',
  props: {
    slice: {
      type: Object,
      required: true,
    },
    fetchSliceData: {
      type: Object,
      required: true,
    },
  },
  setup: async (props) => {
    const i18n = useI18n();
    const sliceData = props.fetchSliceData;
    const { client: $prismic } = usePrismic();
    const { lisaData, setLisaData, onFormLisaSubmit, onFormLisaInput } =
      useLisa({ i18n, sliceData });

    setLisaData();

    const { data } = await useAsyncData(
      `lisaFormHero-${props.slice.id}`,
      async () => {
        return await lisaContentAdapter(props.slice, {
          prismic: $prismic,
          i18n,
          lisaData: lisaData.value,
          useLisaTitle: true,
        });
      },
    );

    const heroLisaData = {
      formData: data?.value.formLisaData?.formData,
      title: data.value.title,
    };

    sliceData.formLisaData = heroLisaData; // seems wrong

    return {
      heroLisaData,
      onFormLisaSubmit,
      onFormLisaInput,
    };
  },
};
</script>
