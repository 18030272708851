<template>
  <organisms-edito-rich-text v-if="sliceData" v-bind="sliceData" />
</template>

<script>
import { ref } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import editoRichTextAdapter from '@/slices/EditoRichText/adapter';
import { useNuxtApp } from '#imports';

export default {
  name: 'EditoRichText',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup: (props) => {
    const sliceData = ref(null);
    const { $enhancedLinkSerializer } = useNuxtApp();

    sliceData.value = editoRichTextAdapter(props.slice, {
      enhancedLinkSerializer: $enhancedLinkSerializer,
    });

    return { sliceData };
  },
};
</script>
