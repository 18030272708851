import { useI18n } from '#imports';

export default {
  data() {
    return {
      translations: {},
    };
  },
  beforeMount() {
    // prevent reverting back to not found after hard-loading page.
    const i18n = useI18n();

    i18n.mergeLocaleMessage(i18n.locale, this.translations);
  },
  setup: () => {
    const i18n = useI18n();
    return { i18n };
  },
  methods: {
    async fetchTranslations(module, i18n = null) {
      if (!i18n) {
        i18n = this.i18n;
      }
      if (!i18n) {
        throw new Error('No i18n instance found');
      }

      const locale = i18n.locale.value;
      try {
        const moduleJsonTranslations = await import(
          `../locales/${locale}/${locale}.${module}.json`
        );
        this.translations = moduleJsonTranslations
          ? moduleJsonTranslations.default
          : {};
        i18n.mergeLocaleMessage(locale, this.translations);
      } catch (err) {
        throw new Error(
          `No ${locale} language translation found for ${module}`,
        );
      }
    },
  },
};
