import * as prismicClient from '@prismicio/client';
import { stripRichTextWrapperTag } from '../../utils/html';
import {
  guideLinks,
  routerLinks,
  offerLinks,
  pageLinks,
} from '~/utils/fetch-links';

export const getGuides = async (
  offer,
  $prismic,
  lang,
  isPro = false,
  page = 1,
) => {
  const items = await $prismic.get({
    filters: [
      prismicClient.filter.at('document.type', 'page_guide'),
      offer
        ? prismicClient.filter.at('my.page_guide.offer', offer)
        : prismicClient.filter.missing('my.page_guide.offer'),
      isPro
        ? prismicClient.filter.at('document.tags', ['pro'])
        : prismicClient.filter.not('document.tags', ['pro']),
    ],
    orderings: [
      {
        field: 'my.page_guide.date',
        direction: 'desc',
      },
      {
        field: 'document.last_publication_date',
        direction: 'desc',
      },
    ],
    page,
    fetchLinks: [...routerLinks, ...guideLinks, ...offerLinks, ...pageLinks],
    pageSize: 16,
    lang,
  });

  return {
    totalPages: items.total_pages,
    guides: items.results,
  };
};

export default async (
  { primary, items },
  { pageData: { isPro }, i18n, prismic, enhancedLinkSerializer },
) => {
  const guidesPerOffer = await Promise.all(
    items.map(async (item) => {
      const offerId = item.offer?.id || null;
      const guidesData = await getGuides(
        offerId,
        prismic,
        i18n.localeProperties.value.language,
        isPro,
      );

      return {
        label: item.label,
        items: guidesData.guides,
        link: offerId ? enhancedLinkSerializer(item.offer) : null,
      };
    }),
  );

  return {
    title: primary.title,
    items: guidesPerOffer.map((offer) => ({
      title: { label: offer.label, cta: offer.link || null },
      items: offer.items.map((guide) => ({
        label: stripRichTextWrapperTag(guide.data.title),
        cta: enhancedLinkSerializer({ ...guide, link_type: 'Document' }),
      })),
    })),
  };
};
