<template>
  <april-slice-zone :content="content" :components="components" />
</template>

<script setup>
import { useStore } from 'vuex';
import { onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { components } from '~/slices';
import getHead from '@/utils/get-head';
import setHead from '@/utils/set-head';
import setLisaAutoFill from '@/utils/set-lisa-auto-fill';
import getAltlangs from '@/utils/get-alternate-lang-data';
import setAlternateLanguages from '@/utils/set-alternate-languages';
import resetTopHeader from '@/utils/reset-top-header';
import { isProRoute } from '@/utils/helpers/get-is-pro-route';
import {
  routerLinks,
  guideLinks,
  articleLinks,
  offerLinks,
  productLinks,
  regionLinks,
  countryLinks,
  ratingLinks,
  topHeaderLinks,
} from '~/utils/fetch-links';
import {
  LAYOUT_SET_FOOTER_VARIANT,
  LAYOUT_SET_HEADER_LITE,
  TRACKING_SET_DATA,
} from '@/store/mutationTypes';
import {
  useI18n,
  usePrismic,
  useAsyncData,
  createError,
  useNuxtApp,
  useHead,
} from '#imports';

const i18n = useI18n();
const lang = i18n.localeProperties.value.language;
const route = useRoute();
const pageUID = isProRoute(route) ? 'home-pro' : 'home';
const { client: $prismic } = usePrismic();
const context = useNuxtApp();

const page = await useAsyncData('home', async () => {
  const document = await $prismic.getByUID('home_page', pageUID, {
    fetchLinks: [
      ...routerLinks,
      ...offerLinks,
      ...regionLinks,
      ...countryLinks,
      ...guideLinks,
      ...articleLinks,
      ...productLinks,
      ...ratingLinks,
      ...topHeaderLinks,
    ],
    lang,
  });

  return document;
});
const { data } = page;
const content = page.data._value;

if (!content) {
  throw createError({ statusCode: 404 });
}

const altLangs = await getAltlangs(page, $prismic);

const store = useStore();
setHead(content?.data, store);
setLisaAutoFill(data, store, i18n);
setAlternateLanguages(altLangs?.results, store);
resetTopHeader(content?.data?.slices, store);

store.commit(`tracking/${TRACKING_SET_DATA}`, { pageType: content.type });

useHead(getHead(context, [], { hasOrganization: true }));

onBeforeUnmount(() => {
  store.commit(`layout/${LAYOUT_SET_HEADER_LITE}`, false);
  store.commit(`layout/${LAYOUT_SET_FOOTER_VARIANT}`, null);
});
</script>
