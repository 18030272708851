<template>
  <div></div>
</template>

<script setup>
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import { LAYOUT_SET_TOP_HEADER } from '@/store/mutationTypes';
import topHeaderAdapter from '@/slices/TopHeader/adapter';
import { useI18n, useNuxtApp } from '#imports';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const i18n = useI18n();
const { $enhancedLinkSerializer } = useNuxtApp();
const store = useStore();

onMounted(() => {
  const topHeader = topHeaderAdapter(props.slice, {
    i18n,
    enhancedLinkSerializer: $enhancedLinkSerializer,
  });

  store.commit(`layout/${LAYOUT_SET_TOP_HEADER}`, {
    ...topHeader,
  });
});
</script>
