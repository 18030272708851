import { capitalizeFirstLetter } from '../text';
import { richText, stripRichTextWrapperTag } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';
import { getInsuranceRoot } from '~/config/routes';

export const getDate = (date, locale) =>
  new Intl.DateTimeFormat(locale, {
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(date));

export const getType = (contentType) => {
  let type;

  switch (contentType) {
    case 'guide':
      type = 'page_guide';
      break;
    case 'region':
      type = 'page_region';
      break;
    case 'country':
      type = 'page_country';
      break;
    case 'product':
      type = 'product_page';
      break;
    case 'article':
    default:
      type = 'page_article';
      break;
  }
  return type;
};

export const getCardType = (type) => {
  let cardType;
  switch (type) {
    case 'page_guide':
      cardType = 'guide';
      break;
    case 'page_region':
      cardType = 'region';
      break;
    case 'page_country':
      cardType = 'country';
      break;
    case 'product_page':
      cardType = 'product';
      break;
    case 'page_article':
    default:
      cardType = 'article';
      break;
  }
  return cardType;
};

export const cardCountryAdapter = ({
  editoCarouselCard,
  cardType,
  enhancedLinkSerializer,
}) => {
  const {
    data: { region, card },
  } = editoCarouselCard || {};

  // get url route param for link
  const urlParams = { region: region?.data?.uid };

  return {
    title: stripRichTextWrapperTag(card[0].card_title),
    subtitle: region?.data?.title,
    type: cardType,
    image: imageAdapter(card[0].card_image, card[0].card_image_alt),
    link: enhancedLinkSerializer({ ...editoCarouselCard, ...urlParams }),
  };
};

// Guide
export const cardGuideAdapter = ({
  editoCarouselCard,
  cardType,
  i18n,
  enhancedLinkSerializer,
}) => {
  const { data, offerTitle } = editoCarouselCard || {};
  const lang = i18n.localeProperties.value.language;
  const subtitle = offerTitle || capitalizeFirstLetter(getInsuranceRoot(lang));

  if (!data) return;
  return {
    title: stripRichTextWrapperTag(data.title),
    subtitle: data.offer?.data?.title || subtitle,
    date: data.date && getDate(data.date, i18n.locale),
    readingTime:
      data.reading_time &&
      `${i18n.t('article.reading-time', {
        readingTime: data.reading_time,
      })}`,
    type: cardType,
    description: richText(data.excerpt),
    link: enhancedLinkSerializer(editoCarouselCard),
  };
};

// Articles
export const cardArticleAdapter = ({
  editoCarouselCard,
  cardType,
  i18n,
  enhancedLinkSerializer,
}) => {
  const { data } = editoCarouselCard || {};
  if (!data) return;
  return {
    title: stripRichTextWrapperTag(data.title),
    subtitle: data.offer?.data?.title,
    date: data.date && getDate(data.date, i18n.locale),
    readingTime:
      data.reading_time &&
      `${i18n.t('article.reading-time', {
        readingTime: data.reading_time,
      })}`,
    type: cardType,
    image: imageAdapter(data.image, data.image_alt),
    link: enhancedLinkSerializer(editoCarouselCard),
  };
};

// Region
export const cardRegionAdapter = ({
  editoCarouselCard,
  cardType,
  enhancedLinkSerializer,
}) => {
  const {
    data: { title, card },
  } = editoCarouselCard || {};
  return {
    title: stripRichTextWrapperTag(title),
    type: cardType,
    image: imageAdapter(card.card_image, card.card_image_alt),
    link: enhancedLinkSerializer(editoCarouselCard),
  };
};

// Region
export const cardProductAdapter = ({
  editoCarouselCard,
  cardType,
  enhancedLinkSerializer,
}) => {
  const {
    data: { offer, card },
  } = editoCarouselCard || {};

  // get url route param for link
  const urlParams = { offer: offer?.uid };

  return {
    title: stripRichTextWrapperTag(card[0].card_title),
    subtitle: offer?.data?.title,
    type: cardType,
    image: imageAdapter(card[0].card_image, card[0].card_image_alt),
    link: enhancedLinkSerializer({ ...editoCarouselCard, ...urlParams }),
  };
};
