<template>
  <organisms-form-lisa-panel
    v-if="sliceData"
    ref="lisa"
    v-bind="sliceData"
    :on-form-lisa-input="onFormLisaInput"
    :on-form-lisa-submit="onFormLisaSubmit"
  />
</template>

<script>
import lisaAdapter from '@/slices/LisaContent/adapter';
import translations from '@/mixins/componentTranslations.js';
import lisa from '@/mixins/lisa';
import { useI18n } from '#imports';

export default {
  name: 'LisaPanel',
  mixins: [translations, lisa],
  props: {
    uid: {
      type: String,
      default: 'part-lisa-common',
    },
  },
  setup: () => {
    const i18n = useI18n();
    return {
      i18n,
    };
  },
  computed: {
    locale() {
      const i18n = useI18n();

      return i18n.localeProperties.value.language;
    },
  },
  watch: {
    locale(value) {
      this.setLisaPanel();
      this.lang = value;
    },
  },
  async mounted() {
    await this.setLisaData();
    this.setLisaPanel();
  },
  methods: {
    async setLisaPanel() {
      await this.fetchTranslations('lisa');

      const data = await lisaAdapter(
        {},
        {
          prismic: this.$prismic.client,
          i18n: this.i18n,
          lisaData: this.lisaData,
          uid: this.uid,
        },
      );
      this.sliceData = data;
    },
  },
};
</script>
