import { plugin as adUIPlugin } from 'UI/store/ui/plugin';
import { Store } from 'vuex';
import * as ui from '../store/ui';
import * as settings from '../store/settings';
import * as tracking from '../store/tracking';
import * as layout from '../store/layout';
import * as index from '../store/index';
import * as ekomi from '../store/ekomi';

export default defineNuxtPlugin((nuxtApp) => {
  const store = new Store({
    modules: {
      index,
      layout: {
        namespaced: true,
        ...layout,
      },
      settings: {
        namespaced: true,
        ...settings,
      },
      tracking: {
        namespaced: true,
        ...tracking,
      },
      ui: {
        namespaced: true,
        ...ui,
      },
      ekomi: {
        namespaced: true,
        ...ekomi,
      },
    },
    plugins: [adUIPlugin],
  });

  nuxtApp.vueApp.use(store);

  if (typeof window === 'undefined') {
    nuxtApp.payload.vuex = store.state;
  } else if (nuxtApp.payload && nuxtApp.payload.vuex) {
    store.replaceState(nuxtApp.payload.vuex);
  }

  return {
    provide: {
      store,
    },
  };
});
