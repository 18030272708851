<template>
  <organisms-edito-cta v-if="sliceData" v-bind="sliceData" />
</template>

<script>
import { ref } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import editoCtaAdapter from '@/slices/EditoCta/adapter';
import { useNuxtApp } from '#imports';

export default {
  name: 'EditoCta',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup: (props) => {
    const { $enhancedLinkSerializer } = useNuxtApp();
    const sliceData = ref(null);

    sliceData.value = editoCtaAdapter(props.slice, {
      enhancedLinkSerializer: $enhancedLinkSerializer,
    });

    return {
      sliceData,
    };
  },
};
</script>
