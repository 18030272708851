<template>
  <component
    :is="component"
    v-if="component && sliceData"
    v-bind="sliceData.content"
    ref="formWrapper"
  >
    <organisms-form-block
      v-if="!submitSuccess"
      v-bind="sliceData.form.data"
      :error="hasErrors ? errorLabel : null"
      :card-color="`#ffffff`"
      :loading="isLoading"
      :disabled="isDisabled"
      @submit="handleSubmit"
    >
      <template #captcha>
        <div id="recaptcha-wrapper"></div>
      </template>
    </organisms-form-block>
    <organisms-confirmation-block
      v-else
      v-bind="sliceData.form.confirmationData"
      :narrow="component === 'organisms-form-push'"
      scroll-on-mount
    />
  </component>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { getSliceComponentProps } from '@prismicio/vue';
import * as prismic from '@prismicio/client';
import FormPush from '@aprildirect/april-direct-ui/lib/components/organisms/form-push/form-push.vue';
import FormSimple from '@aprildirect/april-direct-ui/lib/components/organisms/form-simple/form-simple.vue';
import formAdapter, { formNoResultAdapter } from './adapter';
import submitMailto from './mailto/submit';
import { pushFormSubmit } from '@/plugins/gtm.client';
import {
  createError,
  useI18n,
  usePrismic,
  useHead,
  useNuxtApp,
  useRuntimeConfig,
  useAsyncData,
} from '#imports';
import { useTranslations } from '~/composables/componentTranslations';

const components = {
  default: 'organisms-form-push',
  singleColumn: 'organisms-form-simple',
};

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Form',
  components: {
    'organisms-form-push': FormPush,
    'organisms-form-simple': FormSimple,
  },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup: async (props) => {
    const i18n = useI18n();
    const { language: lang } = i18n.localeProperties.value;
    const { client: $prismic } = usePrismic();
    const formWrapper = ref(null);
    const isDisabled = ref(true);
    const hasErrors = ref(false);
    const isLoading = ref(false);
    const submitSuccess = ref(false);
    const { fetchTranslations, translations } = useTranslations();
    const { $enhancedLinkSerializer, $gtm, $store } = useNuxtApp();
    const config = useRuntimeConfig();

    useHead({
      script: [
        {
          src: 'https://www.google.com/recaptcha/api.js',
          async: true,
          defer: true,
        },
      ],
    });

    onMounted(() => {
      const interval = setInterval(() => {
        if (
          typeof window.grecaptcha?.render !== 'undefined' &&
          formWrapper.value
        ) {
          clearInterval(interval);
          initRecaptcha();
        }
      }, 50);
    });

    const { data: formData } = await useAsyncData(
      `formData-${props.slice.id}`,
      async () => {
        const formId = props.slice.primary.form_module
          ? props.slice.primary.form_module.id
          : props.slice.primary.no_result_module_form_mailto.id;

        if (!formId) {
          throw createError({
            statusCode: 404,
            statusMessage: 'Form module not found',
          });
        }

        try {
          const req = await $prismic.get({
            filters: [prismic.filter.in('document.id', [formId])],
            lang,
          });

          const data = req.results[0]?.data;

          if (data) {
            await fetchTranslations('formsFields');
            return data;
          } else {
            throw createError({
              statusCode: 404,
              statusMessage: 'Form module not found',
            });
          }
        } catch (e) {
          throw createError({
            statusCode: 500,
            statusMessage: 'Error fetching form module',
          });
        }
      },
    );

    const initRecaptcha = () => {
      const { gRecaptchaKey } = config.public || {};

      if (!gRecaptchaKey) {
        return;
      }

      const formElement = formWrapper.value?.$el;
      const recaptchaWrapper = formElement?.querySelector('#recaptcha-wrapper');

      window.grecaptcha.render(recaptchaWrapper, {
        sitekey: gRecaptchaKey,
        callback: () => {
          isDisabled.value = false;
        },
        'expired-callback': () => {
          isDisabled.value = true;
        },
      });
    };

    const handleSubmit = async (formData) => {
      const formElement = formWrapper.value?.$el;
      hasErrors.value = false;
      isLoading.value = true;

      if (sliceData.value.type === 'mailto' && formElement) {
        const { gatewayId, gatewaySecret, gatewayAuthUrl } = config.public;
        const routingInput = formElement.querySelector('#routingInput');
        const objectInput = formElement.querySelector('[name=object]');
        const objectValue = objectInput
          ? Array.from(objectInput.selectedOptions)[0]?.innerHTML.trim()
          : null;

        const { routing, templateId, recipients, applicant } =
          sliceData.value.form;

        await submitMailto(
          {
            formData,
            routingInput,
            routingRule: routing,
            objectValue,
            templateId,
            recipients,
            applicant,
            lang: i18n.localeProperties.value.code,
          },
          { gatewayId, gatewaySecret, gatewayAuthUrl },
          axios,
        )
          .then(() => {
            isLoading.value = false;
            submitSuccess.value = true;
            pushFormSubmit(
              { $gtm, store: $store },
              {
                mailObject: objectValue,
                formName: sliceData.value?.form?.data?.title,
              },
            );
          })

          .catch((_err) => {
            isLoading.value = false;
            hasErrors.value = true;
          });
      } else {
        isLoading.value = false;
        hasErrors.value = true;
      }
    };

    // Computed properties
    const errorLabel = computed(() => {
      return translations?.value.error;
    });

    const sliceData = computed(() => {
      if (props.slice && formData.value) {
        return props.slice?.primary?.form_module
          ? formAdapter(
              {
                slice: props.slice,
                form: {
                  ...formData.value,
                  ...props.slice?.primary?.form_module?.data,
                },
              },
              {
                lang: i18n.locale.value,
                translations: translations.value,
                enhancedLinkSerializer: $enhancedLinkSerializer,
              },
            )
          : formNoResultAdapter(
              { slice: props.slice, form: formData.value },
              {
                lang: i18n.locale.value,
                translations: translations.value,
                enhancedLinkSerializer: $enhancedLinkSerializer,
              },
            );
      }
      return null;
    });

    const component = computed(() => {
      return components[props.slice?.variation] || null;
    });

    return {
      formData,
      errorLabel,
      sliceData,
      component,
      formWrapper,
      isDisabled,
      handleSubmit,
      isLoading,
      submitSuccess,
      hasErrors,
    };
  },
};
</script>

<style>
#recaptcha-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}
</style>
