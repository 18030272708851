<template>
  <organisms-edito-table v-if="sliceData" v-bind="sliceData" />
</template>

<script setup>
import { getSliceComponentProps } from '@prismicio/vue';
import editoTableAdapter from '@/slices/EditoTable/adapter';
import { useI18n, usePrismic, useAsyncData } from '#imports';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const i18n = useI18n();
const { client: $prismic } = usePrismic();

const { data: sliceData } = await useAsyncData(
  `editoTable-${props.slice.id}`,
  async () => {
    return await editoTableAdapter(props.slice, {
      prismic: $prismic,
      i18n,
    });
  },
);
</script>
