export const asianCountries = ['HK', 'SG', 'TH', 'VN', 'PH', 'ID', 'AE'];

export const routingLabelsToKeys = {
  'Individual - Contract Informations': 'partContractInfo',
  'Individual - AI Offers Informations': 'partOffersInfo',
  'Individual_Company - Termination': 'clientTermination',
  'Company - Contract Informations': 'proContractInfo',
  'Company - AI Offers Informations': 'proOffersInfo',
  'School - Contract Informations': 'schoolContractInfo',
  'School - AI Offers Informations': 'schoolOffersInfo',
  'Broker - France/Monaco Affiliation': 'brokerFrance',
  'Broker - Other Affiliation': 'brokerOther',
  'Health - Affiliation Information': 'health',
};

export const routingRules = {
  partContractInfo: [
    {
      id: 'apa',
      email: 'info.expat@april-international.com',
      test: (value) => value.startsWith('APA'),
    },
    {
      id: 'social12',
      email: 'info.expat@april-international.com',
      test: (value) => value.startsWith('1') || value.startsWith('2'),
    },
    {
      id: 'social3',
      email: [
        'info@april-international.co.uk',
        'genwebformapril@robot.zapier.com',
      ],
      test: (value) => value.startsWith('3'),
    },
    {
      id: 'social1',
      email: [
        'info@april-international.co.uk',
        'genwebformapril@robot.zapier.com',
      ],
      test: (value) => value.startsWith('MED'),
    },
    {
      id: 'other',
      email: 'contact.hk@april.com',
    },
  ],
  partOffersInfo: [
    {
      id: 'fr',
      email: 'conseillers.expat@april-international.com',
      test: (value) => value === 'FR',
    },
    {
      id: 'ge',
      email: 'beratungsservice.expat@april-international.com',
      test: (value) => value === 'DE',
    },
    {
      id: 'uk',
      email: [
        'info@april-international.co.uk',
        'genwebformapril@robot.zapier.com',
      ],
      test: (value) => value === 'GB',
    },
    {
      id: 'sw',
      email: 'conseillers.expat@april-international.com',
      test: (value) => value === 'CH',
    },
    {
      id: 'nl',
      email: 'contact@secara.fr',
      test: (value) => value === 'NL',
    },
    {
      id: 'asia',
      email: ['advisors.asia@april.com', 'genwebformapril@robot.zapier.com'],
      test: (value) => asianCountries.includes(value),
    },
    {
      id: 'other',
      email: 'conseillers.expat@april-international.com',
    },
  ],
  proContractInfo: [
    {
      id: 'westEur',
      email: 'info.expat@april-international.com',
      test: (value) => ['FR', 'DE'].includes(value),
    },
    {
      id: 'uk',
      email: [
        'info@april-international.co.uk',
        'genwebformapril@robot.zapier.com',
      ],
      test: (value) => value === 'GB',
    },
    {
      id: 'sw',
      email: 'conseillers.expat@april-international.com',
      test: (value) => value === 'CH',
    },
    {
      id: 'asia',
      email: ['advisors.asia@april.com', 'genwebformapril@robot.zapier.com'],
      test: (value) => asianCountries.includes(value),
    },
    {
      id: 'other',
      email: 'info.expat@april-international.com',
    },
  ],
  proOffersInfo: [
    {
      id: 'france',
      email: 'conseillers.expat@april-international.com',
      test: (value) => value === 'FR',
    },
    {
      id: 'germany',
      email: 'beratungsservice.expat@april-international.com',
      test: (value) => value === 'DE',
    },
    {
      id: 'uk',
      email: [
        'info@april-international.co.uk',
        'genwebformapril@robot.zapier.com',
      ],
      test: (value) => value === 'GB',
    },
    {
      id: 'switzerland',
      email: 'conseillers.expat@april-international.com',
      test: (value) => value === 'CH',
    },
    {
      id: 'asia',
      email: 'corporate.asia@april.com',
      test: (value) => asianCountries.includes(value),
    },
    {
      id: 'other',
      email: 'conseillers.expat@april-international.com',
    },
  ],
  schoolContractInfo: [
    {
      id: 'fr',
      email: 'conseillers.expat@april-international.com',
      test: (value) => value === 'FR',
    },
    {
      id: 'uk',
      email: [
        'info@april-international.co.uk',
        'genwebformapril@robot.zapier.com',
      ],
      test: (value) => value === 'GB',
    },
    {
      id: 'sw',
      email: 'conseillers.expat@april-international.com',
      test: (value) => value === 'CH',
    },
    {
      id: 'germany',
      email: 'kundenbetreuung@april.com',
      test: (value) => value === 'DE',
    },
    {
      id: 'asia',
      email: 'contact.hk@april.com',
      test: (value) => asianCountries.includes(value),
    },
    {
      id: 'other',
      email: 'conseillers.expat@april-international.com',
    },
  ],
  schoolOffersInfo: [
    {
      id: 'france',
      email: [
        'hafsia.durand@april.com',
        'conseillers.expat@april-international.com',
      ],
      test: (value) => value === 'FR',
    },
    {
      id: 'uk',
      email: [
        'info@april-international.co.uk',
        'genwebformapril@robot.zapier.com',
      ],
      test: (value) => value === 'GB',
    },
    {
      id: 'switzerland',
      email: 'conseillers.expat@april-international.com',
      test: (value) => value === 'CH',
    },
    {
      id: 'germany',
      email: 'beratungsservice.expat@april-international.com',
      test: (value) => value === 'DE',
    },
    {
      id: 'asia',
      email: 'corporate.asia@april.com',
      test: (value) => asianCountries.includes(value),
    },
    {
      id: 'other',
      email: [
        'hafsia.durand@april.com',
        'conseillers.expat@april-international.com',
      ],
    },
  ],
  brokerFrance: [
    {
      id: 'market1',
      email: 'courtage.expat@april-international.com',
      test: (value) => ['singleMarket', 'schoolMarket'].includes(value),
    },
    {
      id: 'market2',
      email: 'info.entreprise@april.com',
      test: (value) => value === 'collectiveMarket',
    },
  ],
  brokerOther: [
    {
      id: 'market1',
      email: 'broker.expat@april-international.com',
      test: (value) => value === 'singleMarket',
    },
    {
      id: 'market2',
      email: 'info.entreprise@april.com',
      test: (value) => value === 'collectiveMarket',
    },
  ],
  health: [
    {
      id: 'contract',
      email: 'medicalnetworkaicf@april.com',
      test: (value) => value === 'contract',
    },
    {
      id: 'bill',
      email: 'hospitalisation.expat@april-international.com',
      test: (value) => value === 'bill',
    },
    { id: 'other', email: 'medicalnetworkaicf@april.com' },
  ],
  clientTermination: [
    {
      id: 'international',
      email: 'suiviclient.expat@april-international.com',
      test: (value) => value === 'international',
    },
    {
      id: 'health',
      email: 'serviceclient@april.com',
      test: (value) => value === 'health',
    },
    {
      id: 'partenaires',
      email: 'risquesparticuliers@april-partenaires.fr',
      test: (value) => value === 'partenaires',
    },
    {
      id: 'marine',
      email: 'resiliation@aprilmarine.com',
      test: (value) => value === 'marine',
    },
    {
      id: 'moto',
      email: 'Moncontrat@april-moto.com',
      test: (value) => value === 'moto',
    },
    {
      id: 'enterprise',
      email: 'mdf.ae@april.com',
      test: (value) => value === 'enterprise',
    },
    {
      id: 'other',
      email: 'suiviclient.expat@april-international.com',
      test: (value) => value === 'other',
    },
  ],
};

export const routingKeys = Object.keys(routingRules);
