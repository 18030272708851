<template>
  <Layout>
    <slice-zone
      v-if="data?.slices"
      :slices="data.slices"
      :components="components"
      :context="data"
    />
  </Layout>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, onMounted, watch, computed, defineAsyncComponent } from 'vue';
import * as prismic from '@prismicio/client';
import { components } from '~/slices';
import getHead from '@/utils/get-head';
import setHead from '@/utils/set-head';
import setLisaAutoFill from '@/utils/set-lisa-auto-fill';
import setAlternateLanguages from '@/utils/set-alternate-languages';
import { usePrismic, useI18n, useNuxtApp, useHead } from '#imports';

const Layout = defineAsyncComponent(() => import('@/components/layout'));

const i18n = useI18n();
const { language: lang } = i18n.localeProperties.value;
const { client: $prismic } = usePrismic();
const store = useStore();
const context = useNuxtApp();

const data = ref(null);

const locale = computed(() => lang);

const getData = async () => {
  const page = await $prismic.getSingle('page_error', { lang });
  const alternateIds = page.alternate_languages.map((lang) => lang.id);

  data.value = page?.data;

  const altLangs = await $prismic.get({
    filters: [prismic.filter.in('document.id', alternateIds)],
    lang: '*',
  });

  setHead(page?.data, store);
  setLisaAutoFill(page?.data, store, i18n);
  setAlternateLanguages(altLangs?.results, store);
};

useHead(getHead(context, [], { hasOrganization: true }));

watch(locale, getData);

onMounted(getData);
</script>
