import { ref, reactive, useTemplateRef } from 'vue';
// import { stripRichTextWrapperTag } from '@/utils/html';
import { useStore } from 'vuex';
import { useGtm } from '@gtm-support/vue-gtm';
import { pushLisaForm } from '@/plugins/gtm.client';
// import { generateFormData } from '~/slices/LisaContent/adapter';
import backupData from '@/public/data/lisa.json';
import { navigateTo } from '#imports';
// import { useTranslations } from '@/composables/componentTranslations';

export default ({ i18n, sliceData }) => {
  const lang = i18n.localeProperties.value.language;
  const lisaData = ref(null);
  const lisa = useTemplateRef('lisa');
  const heroLisaDefaultData = ref(null);
  const residence = ref(null);
  const destination = ref(null);
  const profile = ref(null);
  const product = ref(null);
  const funnel = ref(null);
  const nationality = ref(null);
  const nationalityNUllId = ref(null);
  const submitted = ref(false);
  const form = ref(null);
  const trackingData = reactive({
    residence: null,
    destination: null,
    profile: null,
    nationality: null,
  });
  const store = useStore();
  const $gtm = useGtm();

  // const { fetchTranslations } = useTranslations();

  // const temporaryTitle = [
  //   {
  //     type: 'paragraph',
  //     text: 'Find the right insurance for you:',
  //     spans: [
  //       {
  //         type: 'label',
  //         data: {
  //           label: 'black-color',
  //         },
  //       },
  //     ],
  //   },
  // ];

  // await fetchTranslations('lisa', i18n);
  // heroLisaDefaultData.value = {
  //   formData: generateFormData(i18n),
  //   title: stripRichTextWrapperTag(temporaryTitle),
  // };

  const redirect = (path) => {
    const isPathExternal = path.includes('http');

    if (isPathExternal) {
      window.location.href = path;
    } else {
      navigateTo({ path });
    }
  };

  const onFormLisaSubmit = (data) => {
    // Tracking
    const resultType = data?.type === 'quote' ? 'quote engine' : 'product page';
    const { residence, destination, profile, nationality } = trackingData || {};
    pushLisaForm(
      { store, $gtm },
      {
        lisaPaysResidence: residence.value,
        lisaPaysDestination: destination.value,
        lisaBesoin: profile.value,
        lisaPaysNationality: nationality.value,
        resultType,
      },
    );

    const isSubmitFunnelBtn = data?.type === 'quote';

    // Redirect
    if (product.value && !isSubmitFunnelBtn) {
      redirect(product.value.fields[`url_${lang.value}`]);
      product.value = null;
    }

    if (funnel.value && isSubmitFunnelBtn) {
      redirect(funnel.value.fields[`url_${lang.value}`]);
      funnel.value = null;
    }

    // Reset lisa module
    for (
      let index = 0;
      index < sliceData.formLisaData.formData.inputs.length;
      index++
    ) {
      resetInput(index, index !== 0, index !== 0);
    }
    // Desactivate lisa panel buttons
    if (sliceData.submitCta) sliceData.submitCta.disabled = true;
    if (sliceData.cta) sliceData.cta.disabled = true;
  };

  const onFormLisaInput = (inputData) => {
    form.value = inputData.form;

    // reset form result on input change
    product.value = null;
    funnel.value = null;

    // desactivate buttons on input change
    if (sliceData.cta) {
      sliceData.cta.disabled = true;
    }
    if (sliceData.submitCta) sliceData.submitCta.disabled = true;
    if (sliceData.formLisaData.formData.buttons?.length)
      sliceData.formLisaData.formData.buttons[0].cta.disabled = true;

    if (inputData && inputData.input) {
      if (inputData.input.name === i18n.t('name.residence')) {
        onInputResidence(inputData);
      }

      if (inputData.input.name === i18n.t('name.destination')) {
        onInputDestination(inputData);
      }

      if (inputData.input.name === i18n.t('name.profile')) {
        onInputProfile(inputData);
      }

      if (inputData.input.name === i18n.t('name.nationality')) {
        onInputNationality(inputData);
      }
    }
  };

  const onInputResidence = (inputData) => {
    residence.value = inputData.input.value;
    trackingData.residence = getIsoFromId(
      residence.value,
      lisaData.value.countries,
    );
    resetAllFieldsAfter(0);
    setNationalityFieldActive(false);

    const residenceCountriesLists = getCountriesLists(residence.value);

    const flowsLists = getFlowsListsFrom(residenceCountriesLists);

    let destinationCountries = flowsLists.map((flow) => {
      let destinations = flow.fields.destination;

      destinations = destinations
        .map(
          (listId) =>
            lisaData.value?.countriesLists.find((list) => list.id === listId)
              .fields.countries,
        )
        .filter((list) => list !== undefined);

      return destinations;
    });
    destinationCountries = flatten(destinationCountries, 2).sort(
      sortAlphabetically,
    );

    const uniqDestinationCountries = [
      ...new Map(destinationCountries.map((item) => [item.id, item])).values(),
    ];

    if (uniqDestinationCountries.length > 0) {
      setInput(1, uniqDestinationCountries);
    }
  };

  const onInputDestination = (inputData) => {
    destination.value = inputData.input.value;
    trackingData.destination = getIsoFromId(
      destination.value,
      lisaData.value.countries,
    );
    resetAllFieldsAfter(1);
    setNationalityFieldActive(false);

    const residenceCountriesLists = getCountriesLists(residence.value);

    const destinationCountriesLists = getCountriesLists(destination.value);

    let flowsLists = getFlowsListsFrom(residenceCountriesLists);

    flowsLists = flowsLists.filter((flow) =>
      destinationCountriesLists.some((destination) =>
        flow.fields.destination?.includes(destination.id),
      ),
    );

    const profilesList = flatten(
      flowsLists.map((flow) => flow.fields.profile),
    ).map((profileId) =>
      lisaData?.value.profiles.filter((profile) => profile.id === profileId),
    );

    const orderedProfilesList = flatten(profilesList).sort(sortByOrder);

    if (orderedProfilesList.length > 0) {
      setInput(2, [...new Set(orderedProfilesList)]);
    }
  };

  const onInputProfile = (inputData) => {
    profile.value = inputData.input.value;
    trackingData.profile = getValueFromId(
      profile.value,
      lisaData.value.profiles,
    );

    resetAllFieldsAfter(2);

    const residenceCountriesLists = getCountriesLists(residence.value);

    const destinationCountriesLists = getCountriesLists(destination.value);

    const flows = getFlowsListsFrom(residenceCountriesLists)
      .filter((flow) =>
        destinationCountriesLists.some((destination) =>
          flow.fields.destination?.includes(destination.id),
        ),
      )
      .filter((flow) => flow.fields.profile.includes(profile.value));

    let nationalitiesLists = flatten(
      flows.map((flow) => flow.fields.nationality),
    );
    if (
      flows.length > 1 &&
      !nationalitiesLists.includes(nationalityNUllId.value)
    ) {
      nationalitiesLists = flatten(
        nationalitiesLists
          .map((listId) =>
            lisaData?.value.countriesLists.find(({ id }) => listId === id),
          )
          .map((list) => list.fields.countries)
          .filter((list) => list !== undefined),
      ).sort(sortAlphabetically);

      setInput(3, [...new Set(nationalitiesLists)]);
      setNationalityFieldActive(true);
    } else {
      product.value =
        flows.length &&
        lisaData.value.products.find((product) =>
          flows[0].fields.product.includes(product.id),
        );

      funnel.value =
        flows.length &&
        lisaData.value.funnels.find((funnel) =>
          flows[0].fields.funnel?.includes(funnel.id),
        );

      if (funnel.value && sliceData.cta) {
        sliceData.cta.disabled = false;
      }

      if (sliceData.submitCta)
        sliceData.submitCta.disabled = !(
          residence.value &&
          destination.value &&
          profile.value
        );

      if (sliceData.formLisaData.formData.buttons)
        sliceData.formLisaData.formData.buttons[0].cta.disabled = !(
          residence.value &&
          destination.value &&
          profile.value
        );
    }
  };

  const onInputNationality = (inputData) => {
    nationality.value = inputData.input.value;
    trackingData.nationality = getIsoFromId(
      nationality.value,
      lisaData.value.countries,
    );

    const residenceCountriesLists = getCountriesLists(residence.value);

    const destinationCountriesLists = getCountriesLists(destination.value);

    const nationalitiesLists = getCountriesLists(nationality.value);

    const flow = getFlowsListsFrom(residenceCountriesLists)
      .filter((flow) =>
        destinationCountriesLists.some((destination) =>
          flow.fields.destination?.includes(destination.id),
        ),
      )
      .filter((flow) =>
        nationalitiesLists.some((nationality) =>
          flow.fields.nationality?.includes(nationality.id),
        ),
      )
      .find((flow) => flow.fields.profile.includes(profile));

    product.value =
      flow &&
      lisaData.value.products.find((product) =>
        flow.fields.product.includes(product.id),
      );

    funnel.value =
      flow &&
      lisaData.value.funnels.find((funnel) =>
        flow.fields.funnel?.includes(funnel.id),
      );

    if (funnel.value && sliceData.cta) {
      sliceData.cta.href = funnel.value.fields[`url_${lang.value}`];
      sliceData.cta.disabled = false;

      if (funnel.value.fields[`url_${lang.value}`].includes('http')) {
        sliceData.cta.external = '';
      }
    }

    if (sliceData.submitCta)
      sliceData.submitCta.disabled = !(
        residence.value &&
        destination.value &&
        profile.value &&
        nationality.value
      );

    const buttons = sliceData.formLisaData?.formData?.buttons;
    if (buttons?.length)
      buttons[0].cta.disabled = !(
        residence.value &&
        destination.value &&
        profile.value &&
        nationality.value
      );
  };

  const getCountriesLists = (countryId) => {
    return lisaData.value?.countriesLists.filter((list) =>
      list.fields?.countries?.find(({ id }) => id === countryId),
    );
  };

  const setLisaData = () => {
    lisaData.value = backupData;
    nationalityNUllId.value = backupData?.countriesLists.find(
      (countriesList) => countriesList.fields.Name === 'NULL',
    ).id;
  };

  const getFlowsListsFrom = (countriesLists) => {
    const flowsListsId = lisaData.value
      ? Object.keys(lisaData.value?.lisa)
      : [];
    const flowLists = [];

    countriesLists?.forEach(({ id }) => {
      flowsListsId.forEach((flowId) => {
        if (flowId.includes(id)) flowLists.push(flowId);
      });
    });

    return flatten([...flowLists.map((id) => lisaData.value?.lisa[id])]);
  };

  const getValueFromId = (id, array) => {
    return array.find((item) => item.id === id)?.fields[lang];
  };

  const getIsoFromId = (id, array) => {
    return array.find((item) => item.id === id)?.fields.language;
  };

  const setNationalityFieldActive = (active) => {
    sliceData.formLisaData.formData.inputs[3].field.hidden = !active;
  };

  const setInput = (inputIndex, data) => {
    // activate field
    sliceData.formLisaData.formData.inputs[inputIndex].field.disabled = false;
    sliceData.formLisaData.formData.inputs[inputIndex].field.resetButton = true;

    // populate field with data options
    sliceData.formLisaData.formData.inputs[inputIndex].field.options = data.map(
      ({ id, fields }) => ({
        label: fields[lang],
        value: id,
      }),
    );
  };

  const resetAllFieldsAfter = (inputIndex) => {
    for (
      let index = inputIndex + 1;
      index < sliceData.formLisaData.formData.inputs.length;
      index++
    ) {
      resetInput(index);
    }
  };

  const resetInput = (
    inputIndex,
    doEmptyOptions = true,
    doDisableField = true,
  ) => {
    // empty options
    if (doEmptyOptions) setInput(inputIndex, []);

    // reset form data, slice data states, tracking data
    const name = sliceData.formLisaData.formData.inputs[inputIndex].name;
    form.value[name] = '';
    // thi s[name] = null;
    sliceData.formLisaData.formData.inputs[inputIndex].value = '';

    // reset css styles

    const inputLabel = lisa.value.$el.querySelector(
      `.form-element:nth-of-type(${inputIndex + 1}) .input-label-content`,
    );
    if (inputLabel) inputLabel.classList.remove('-filled', '-select');

    // disable field
    if (doDisableField) {
      sliceData.formLisaData.formData.inputs[inputIndex].field.disabled = true;
      sliceData.formLisaData.formData.inputs[inputIndex].field.resetButton =
        false;
    }
  };

  const flatten = (arr, depth = 1) => {
    return arr.reduce(
      (a, v) =>
        a.concat(depth > 1 && Array.isArray(v) ? flatten(v, depth - 1) : v),
      [],
    );
  };

  const sortAlphabetically = (a, b) => {
    if (a && b && a.fields && b.fields)
      return a.fields[lang]?.localeCompare(b.fields[lang]);
    else return a?.fields?.Name.localeCompare(b?.fields?.Name);
  };

  const sortByOrder = (a, b) => {
    if (a && b && a.fields && b.fields) return a.fields.order - b.fields.order;
  };

  return {
    lang,
    sliceData,
    lisaData,
    heroLisaDefaultData,
    residence,
    destination,
    profile,
    product,
    funnel,
    nationality,
    nationalityNUllId,
    submitted,
    form,
    lisa,
    trackingData,
    onFormLisaSubmit,
    onFormLisaInput,
    setLisaData,
  };
};
