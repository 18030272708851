<template>
  <div>
    <organisms-contact-configurator
      v-if="sliceData"
      v-bind="sliceData"
      @submit="onSubmit"
    />
    <div v-if="contactSlices?.length > 0">
      <slice-zone
        :slices="contactSlices"
        :components="components"
        :context="{ ...slice.data }"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import { components } from '~/slices';
import { contactLinks, routerLinks } from '@/utils/fetch-links';
import contactConfiguratorAdapter from '@/slices/ContactConfigurator/adapter';
import { useI18n, usePrismic } from '#imports';

export default {
  name: 'ContactConfigurator',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup: () => {
    const i18n = useI18n();
    const prismicPlugin = usePrismic();
    const prismic = prismicPlugin.client;
    const sliceData = ref(null);
    const contactSlices = ref(null);

    return {
      i18n,
      prismic,
      sliceData,
      contactSlices,
      components,
    };
  },
  created() {
    this.sliceData = contactConfiguratorAdapter(this.slice, {
      i18n: this.i18n,
    });
  },
  methods: {
    async onSubmit(data) {
      const uid = this.sliceData?.results.find(
        (item) => item.topic === data.topic && item.subtopic === data.subtopic,
      ).uid;

      const document = await this.prismic.getByUID(
        'module_contact_configurator_result',
        uid,
        {
          fetchLinks: [...contactLinks, ...routerLinks],
          lang: this.i18n.localeProperties.value.language,
        },
      );

      if (document && document.data && document.data.body) {
        this.contactSlices = [];
        for (const slice of document.data.body) {
          this.contactSlices.push(slice);
        }
      }
    },
  },
};
</script>
