export default async (websiteLocale = 'US') => {
  if (import.meta.server) {
    return websiteLocale;
  }

  if (!localStorage) {
    return null;
  }
  const countryCode = localStorage.getItem('country-code');

  if (countryCode) {
    return countryCode;
  }

  const response = await fetch('/api/geolocation', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'identity',
    },
  });

  const data = await response.json();
  const value = data?.country || websiteLocale;
  localStorage.setItem('country-code', value);

  return value;
};
