<template>
  <organisms-site-header-light
    v-if="layout.header.isLite"
    id="header"
    v-bind="isPro ? layout.header.pro : layout.header.private"
    :root-link="rootLink"
    :contact="layout.header.isLite.contact"
    :cta="layout.header.isLite.cta?.action"
  >
    <template #topHeader>
      <top-header />
    </template>
  </organisms-site-header-light>
  <organisms-site-header
    v-else
    id="header"
    v-bind="isPro ? layout.header.pro : layout.header.private"
    @open-menu-index="handleOpenMenuIndex"
  >
    <template #topHeader>
      <top-header />
    </template>
  </organisms-site-header>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { LAYOUT_SET_NAV_OPEN_INDEX } from '@/store/mutationTypes';

const TopHeader = defineAsyncComponent(() => import('@/components/top-header'));

export default {
  name: 'SiteHeader',
  components: { TopHeader },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
    isPro() {
      return this.$store.state.layout.isPro;
    },
    rootLink() {
      return this.isPro
        ? this.layout?.header?.pro?.rootLink
        : this.layout?.header?.private?.rootLink;
    },
    nav() {
      return this.isPro
        ? this.layout?.header?.pro?.nav
        : this.layout?.header?.private?.nav;
    },
  },
  methods: {
    handleOpenMenuIndex(openIndex) {
      this.$store.commit(`layout/${LAYOUT_SET_NAV_OPEN_INDEX}`, openIndex);
    },
  },
};
</script>
