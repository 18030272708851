import mailtoAdapter from './mailto/adapter';
import { richText, stripRichTextWrapperTag } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';

export const confirmationAdapter = (data, enhancedLinkSerializer) => {
  let primaryCta, secondaryCta;

  if (data.confirmation_link && data.confirmation_link_label) {
    primaryCta = {
      link: enhancedLinkSerializer(data.confirmation_link),
      label: data.confirmation_link_label,
    };
  }

  if (
    data.confirmation_secondary_link &&
    data.confirmation_secondary_link_label
  ) {
    secondaryCta = {
      link: enhancedLinkSerializer(data.confirmation_secondary_link),
      label: data.confirmation_secondary_link_label,
    };
  }

  return {
    icon: imageAdapter(data.confirmation_icon),
    title: stripRichTextWrapperTag(data.confirmation_title),
    text: richText(data.confirmation_description),
    primaryCta,
    secondaryCta,
  };
};

export default (
  { slice, form },
  { lang, translations, enhancedLinkSerializer },
) => {
  const content = slice.primary;
  let type = null;
  let formData = null;

  const introTitle = stripRichTextWrapperTag(content.intro_title);
  const introSupertitle = richText(content.intro_subtitle);
  const introDescription = richText(content.intro_description);
  const hasIntro = introTitle || introSupertitle || introDescription;

  if (content.form_module.type === 'module_form_mailto') {
    formData = mailtoAdapter(form, {
      lang,
      translations,
      enhancedLinkSerializer,
    });
    type = 'mailto';
  }

  return {
    type,
    content: {
      title: stripRichTextWrapperTag(content.title),
      description: richText(content.description),
      overlap: content.is_overlapping,
      intro: hasIntro
        ? {
            title: introTitle,
            supertitle: introSupertitle,
            description: introDescription,
          }
        : null,
    },
    form: formData,
  };
};

export const formNoResultAdapter = (
  { slice, form },
  { lang, translations, enhancedLinkSerializer },
) => {
  const content = slice.primary;
  let type = null;
  let formData = null;

  if (content.no_result_module_form_mailto.type === 'module_form_mailto') {
    formData = mailtoAdapter(form, {
      lang,
      translations,
      enhancedLinkSerializer,
    });
    type = 'mailto';
  }

  return {
    type,
    content: {
      title: stripRichTextWrapperTag(content.no_result_title),
      description: richText(content.no_result_description),
      overlap: false,
      intro: null,
    },
    form: formData,
  };
};
