import { plugin as adUIPlugin } from 'UI/store/ui/plugin';
import * as prismicClient from '@prismicio/client';
import sanitizeFooter from './sanitizers/footer';
import sanitizeHeader from './sanitizers/header';
import {
  LAYOUT_SET_FOOTER_DATA,
  LAYOUT_SET_HEADER_DATA,
  LAYOUT_SET_HEADER_PRO_DATA,
  LAYOUT_SET_PRO,
  SETTINGS_SET_LOCALES,
  SETTINGS_SET_BASE_URL,
  SET_REDIRECTIONS,
} from './mutationTypes';
import getHeader from '@/utils/helpers/get-header';
import { routerLinks } from '@/utils/fetch-links';
import getUrl from '@/utils/helpers/get-url';
import { isProRoute } from '@/utils/helpers/get-is-pro-route';

export const plugins = [adUIPlugin];

export const state = () => ({
  redirections: [],
});

export const mutations = {
  [SET_REDIRECTIONS](mutationState, data) {
    mutationState.redirections = data;
  },
};

export const fetchHeader = async (
  $prismic,
  commit,
  { i18n, $enhancedLinkSerializer, route },
  lang,
  isPro,
  languages,
) => {
  try {
    const header = await getHeader($prismic, lang, { isPro });
    const commitValue = !isPro
      ? `layout/${LAYOUT_SET_HEADER_DATA}`
      : `layout/${LAYOUT_SET_HEADER_PRO_DATA}`;

    commit(
      commitValue,
      sanitizeHeader(
        { data: header.data, modules: header.modules, languages, lang },
        { i18n, $enhancedLinkSerializer, route },
      ),
    );
  } catch (e) {
    console.warn('An error occured during header fetch.', e);
  }
};

export const fetchFooter = async (
  $prismic,
  commit,
  { i18n, $enhancedLinkSerializer },
  isPro,
  languages,
  lang,
) => {
  try {
    const { data: footerData } = await $prismic.getByUID(
      'module_footer',
      isPro ? 'footer-pro' : 'footer',
      {
        fetchLinks: routerLinks,
        lang,
      },
    );

    commit(
      `layout/${LAYOUT_SET_FOOTER_DATA}`,
      sanitizeFooter(
        { ...footerData, languages },
        { $enhancedLinkSerializer, i18n },
      ),
    );
  } catch (e) {
    console.warn('An error occured during footer fetch.', e);
  }
};

const getLanguages = (locales, lang, i18n) => {
  return locales.map((locale) => {
    return {
      code: locale.code,
      language: locale.language,
      label: i18n.t(`configs.locales.${locale.language}`),
      isCurrent: locale.language === lang,
      prefixIndividual: locale.individual,
      prefiCorporate: locale.corporate,
    };
  });
};

export const actions = {
  async nuxtServerInit({ commit }, { context, i18n, $prismic, route }) {
    const { $enhancedLinkSerializer, req } = context;
    const { locales: refLocales, localeProperties } = i18n;
    // const lang = locale._value
    const locales = refLocales.value;
    const findLocaleLanguage = locales.find(
      (locale) => locale.code === i18n.locale.value,
    )?.language;
    const lang = localeProperties.language || findLocaleLanguage;

    const languages = getLanguages(locales, lang, i18n);

    // Theme
    const isPro = isProRoute(route);
    commit(`layout/${LAYOUT_SET_PRO}`, isPro);

    // Locales

    if (req && req.headers && req.headers.host) {
      const root = getUrl(req);
      commit(`settings/${SETTINGS_SET_BASE_URL}`, root);
    }

    // Locales
    try {
      commit(`settings/${SETTINGS_SET_LOCALES}`, languages);
    } catch (e) {
      console.warn('An error occured storing locales.', e);
    }

    // Redirections
    try {
      const redirectionsData = await $prismic.get({
        filters: [prismicClient.filter.at('document.type', 'redirect')],
      });
      const redirections = redirectionsData.results.map((result) => ({
        from: result.data.redirect_from,
        to: result.data.redirect_to,
      }));
      commit(`${SET_REDIRECTIONS}`, redirections);
    } catch (e) {
      console.warn('An error occured during redirections fetch.', e);
    }

    // Header Data
    await fetchHeader(
      $prismic,
      commit,
      { i18n, $enhancedLinkSerializer, route },
      lang,
      isPro,
      languages,
    );

    // Footer Data
    await fetchFooter(
      $prismic,
      commit,
      { i18n, $enhancedLinkSerializer, route },
      isPro,
      languages,
      lang,
    );
  },

  async loadHeader({ commit }, context) {
    const {
      $prismic,
      $i18n: i18n,
      $enhancedLinkSerializer,
      $route,
      store,
    } = context;
    const isPro = store.state.layout.isPro;

    let locales = i18n.locales;
    let localeProperties = i18n.localeProperties;

    if (typeof window !== 'undefined') {
      locales = locales.value;
      localeProperties = localeProperties.value;
    }

    const lang = localeProperties.language;
    const languages = getLanguages(locales, lang, i18n);
    const shouldLoad = !isPro
      ? !store.state.layout.header.private
      : !store.state.layout.header.pro;
    const headerKey = isPro ? 'pro' : 'private';
    const langSwitch =
      store.state.layout.header[headerKey]?.rootLink?.field?.lang !== lang;

    if (!langSwitch && !shouldLoad) {
      return;
    }

    await fetchHeader(
      $prismic,
      commit,
      { i18n, $enhancedLinkSerializer, $route },
      lang,
      isPro,
      languages,
    );
  },

  async loadFooter({ commit }, context) {
    const { $prismic, $i18n: i18n, $enhancedLinkSerializer, $route } = context;
    const isPro = isProRoute($route);

    let locales = i18n.locales;
    let localeProperties = i18n.localeProperties;

    if (typeof window !== 'undefined') {
      locales = locales.value;
      localeProperties = localeProperties.value;
    }

    const lang = localeProperties.language;
    const languages = getLanguages(locales, lang, i18n);

    await fetchFooter(
      $prismic,
      commit,
      { i18n, $enhancedLinkSerializer, locales },
      isPro,
      languages,
      lang,
    );
  },
};
