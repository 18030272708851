import { TRACKING_SET_DATA } from './mutationTypes';

export const state = () => ({
  data: {
    offer: null,
    product: null,
    page_type: null,
  },
});

export const mutations = {
  // TRACKING
  [TRACKING_SET_DATA](mutationState, data) {
    mutationState.data = data;
  },
};
