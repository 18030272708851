import { stripRichTextWrapperTag, richText } from '../../utils/html';
import { adapter as imageAdapter } from '../../components/cloud-image/utils';

export const getRating = (rating, i18n) => {
  if (!i18n) return;
  return new Intl.NumberFormat(i18n.locale).format(rating);
};

const getFirstRatings = (ratings, i18n, enhancedLinkSerializer) => {
  const items = ratings.slice(0, 3);
  return items.map(
    ({
      rating,
      text,
      date,
      source_link_label: sourceLinkLabel,
      source_link: sourceLink,
    }) => {
      return {
        rating: getRating(rating, i18n),
        text,
        date: new Intl.DateTimeFormat(i18n.locale).format(new Date(date)),
        source: {
          link: enhancedLinkSerializer(sourceLink),
          label: sourceLinkLabel,
        },
        sourceLabel: i18n.t('rating.rating-powered-by'),
      };
    },
  );
};

export default ({ primary }, { i18n, enhancedLinkSerializer }) => {
  if (!primary.rating_module?.data) {
    return null;
  }

  const { data } = primary.rating_module;
  const {
    title,
    text,
    average_rating: averageRating,
    badge,
    badge_alt: badgeAlt,
    view_all_link: viewAllLink,
    view_all_label: viewAllLabel,
    ratings,
  } = data || {};

  const averageRatings = getRating(averageRating, i18n);
  const firstRatings = getFirstRatings(ratings, i18n, enhancedLinkSerializer);

  return {
    title: title && stripRichTextWrapperTag(title),
    text: richText(text),
    badge: badge && imageAdapter(badge, badgeAlt),
    averageRating: averageRatings,
    cards: firstRatings,
    cta: {
      ...enhancedLinkSerializer(viewAllLink),
      label: viewAllLabel || i18n.t('cta.see-all-ratings'),
    },
  };
};
