import { getCountrySelectOptions } from '@/utils/helpers/get-countries';
import { getNeedsSelectOptions } from '@/utils/helpers/get-needs';
import { getLanguageOptions } from '@/utils/helpers/get-languages';

export const getFiltersArrays = ({ i18n }) => {
  const localeProperties = i18n.localeProperties.value;
  const countries = getCountrySelectOptions(localeProperties.code);
  const languages = getLanguageOptions(localeProperties.code);
  return { countries, languages };
};

export default ({ title, i18n }) => {
  const countryList = getCountrySelectOptions(i18n.localeProperties.value.code);
  const needsList = getNeedsSelectOptions(i18n.localeProperties.value.code);

  return {
    title,
    label: i18n.t('filters.products-list.label'),
    selects: [
      {
        name: 'country-of-residence',
        id: 'country_of_residence',
        fieldType: 'country',
        label: i18n.t('filters.products-list.inputs.country-of-residence'),
        type: 'select',
        resetButton: true,
        value: '',
        options: countryList,
      },
      {
        name: 'country-of-destination',
        id: 'country_of_destination',
        fieldType: 'country',
        label: i18n.t('filters.products-list.inputs.country-of-destination'),
        type: 'select',
        resetButton: true,
        value: '',
        options: countryList,
      },
      {
        name: 'needs',
        id: 'needs',
        fieldType: 'need',
        label: i18n.t('filters.products-list.inputs.needs'),
        type: 'select',
        resetButton: true,
        value: '',
        options: needsList,
      },
    ],
  };
};
