import { routingRules } from './routing';
import { countries } from '~/utils/helpers/get-countries.js';

const getToken = ({ gatewayId, gatewaySecret, gatewayAuthUrl }, axios) => {
  return axios({
    method: 'POST',
    url: gatewayAuthUrl,
    params: {
      grant_type: 'client_credentials',
      client_secret: gatewaySecret,
      client_id: gatewayId,
    },
  });
};

export const postData = (
  { token, data, emails, templateId, applicant, objectValue, lang },
  axios,
) => {
  const concatenatedPhone = data.get('country-code') + data.get('phone-cc');
  data.delete('country-code');
  data.delete('phone-cc');
  data.append('phone', concatenatedPhone);
  const variables = Object.fromEntries(data);

  const modifiers = [
    'residencecountry',
    'destinationcountry',
    'location',
    'structureAddress',
    'nationality',
  ];

  modifiers.forEach((modifier) => {
    if (variables[modifier]) {
      const value = countries[variables[modifier]]?.locales[lang];
      if (value) variables[modifier] = value;
    }
  });

  const page = window.location.href;

  return axios({
    method: 'POST',
    url: '/gateway/fr/common/mail/mailjet/v3.1/send',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: {
      Messages: [
        {
          To: emails.map((email) => ({
            Email: email,
            Name: 'April International',
          })),
          TemplateID: templateId,
          TemplateLanguage: true,
          Variables: {
            ...variables,
            applicant,
            object: objectValue || undefined,
            page,
          },
        },
      ],
    },
  });
};

export default (
  {
    formData,
    routingInput,
    routingRule,
    objectValue,
    templateId,
    recipients,
    applicant,
    lang,
  },
  { gatewayId, gatewaySecret, gatewayAuthUrl },
  axios,
) => {
  return new Promise((resolve, reject) => {
    try {
      const emails = [];

      if (routingRule) {
        const value = routingInput?.value;
        if (!value) return;

        const routing = routingRules[routingRule]?.find((rule) => {
          if (['other', 'all'].includes(rule.id)) {
            return true;
          }

          return rule.test(value);
        });

        typeof routing.email === 'string'
          ? emails.push(routing.email)
          : emails.push(...routing.email);
      } else if (recipients) {
        emails.push(...recipients);
      }

      getToken({ gatewayId, gatewaySecret, gatewayAuthUrl }, axios)
        .then((tokenRes) => {
          const token = tokenRes.data?.access_token;

          if (!token) {
            reject(new Error('No access token found'));
          }

          postData(
            {
              token: tokenRes.data.access_token,
              data: formData,
              emails,
              templateId,
              applicant,
              objectValue,
              lang,
            },
            axios,
          )
            .then((mailtoRes) => {
              resolve(mailtoRes.data);
            })
            .catch((err) => {
              reject(err.response);
            });
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (err) {
      reject(err);
    }
  });
};
