<template>
  <div class="app">
    <NuxtLoadingIndicator
      :color="'var(--primary-color, #639E30)'"
      :height="2"
    />
    <SiteHeader
      @open-panel="handleOpenPanel"
      @langSwitched="onLanguageSwitched"
    />
    <div :style="style" :class="[topHeaderHeight > 0 && '__with-top-header']">
      <slot />
    </div>
    <lisa-module-panel
      v-if="withLisaModule"
      ref="formLisaPanel"
      :lisa-uid="lisaUId"
    />
    <lazy-organisms-site-footer
      v-bind="layout?.footer.data"
      :variant="layout?.footer.variant"
      :open-cookies="openCookies"
      @langSwitched="onLanguageSwitched"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { defineNuxtComponent } from '#app';
import {
  useRoute,
  useI18n,
  navigateTo,
  useHead,
  useNuxtApp,
  usePrismic,
} from '#imports';
import { isProRoute } from '@/utils/helpers/get-is-pro-route';
import { LAYOUT_SET_PRO } from '@/store/mutationTypes';
import { pushPageView } from '~/plugins/gtm.client';

const SiteHeader = defineAsyncComponent(() => import('@/components/header'));
const LisaModulePanel = defineAsyncComponent(
  () => import('@/components/lisa-module-panel'),
);

export default defineNuxtComponent({
  name: 'AppLayout',
  components: {
    SiteHeader,
    LisaModulePanel,
  },
  data() {
    return {
      isSitePro: isProRoute(this.route),
      withLisaModule: this.$config.public.lisaEnabled,
    };
  },
  computed: {
    lisaUId() {
      const header = this.isPro
        ? this.store.state.layout.header.pro
        : this.store.state.layout.header.private;
      const lisaAction = header?.actions?.find((action) => action.lisaUId);
      return lisaAction?.lisaUId || null;
    },
    layout() {
      return this.store.state.layout;
    },
    isPro() {
      return this.store.state.layout.isPro;
    },
    altLanguagesData() {
      return this.store.state.layout.altLanguages;
    },
    languages() {
      return this.store.state.settings.languages;
    },
    locale() {
      return this.i18n.localeProperties.value.language;
    },
    topHeaderHeight: function () {
      return this.store.state.layout.topHeader?.height || 0;
    },
    style: function () {
      return {
        '--top-header-height': `${this.topHeaderHeight}px`,
      };
    },
  },
  watch: {
    locale() {
      this.loadHeaderFooter();
      window &&
        window.axeptioSDK.setCookiesVersion(
          this.i18n?.localeProperties.value.code?.toUpperCase(),
        );
    },
    $route(to) {
      pushPageView({ $gtm: this.$gtm, route: this.route, store: this.store });
      const isPro = isProRoute(to);
      this.store.commit(`layout/${LAYOUT_SET_PRO}`, isPro);
      this.isSitePro = isPro;
      if (this.store.state.ui.overlay.opened)
        this.store.commit('ui/toggleOverlay');
    },
    isSitePro() {
      this.loadHeaderFooter();
    },
  },
  methods: {
    loadHeaderFooter() {
      const context = {
        ...this,
        $i18n: this.i18n,
        $route: this.route,
        $enhancedLinkSerializer: this.enhancedLinkSerializer,
        $prismic: this.prismic,
        store: this.store,
      };

      this.store.dispatch('loadHeader', context);
      this.store.dispatch('loadFooter', context);
    },
    handleOpenPanel(panel) {
      this.$nextTick(() => {
        if (panel === 'lisa_panel')
          if (this.$refs.formLisaPanel.$refs?.lisa) {
            this.$refs.formLisaPanel.$refs?.lisa?.handleToggle();
            this.$refs.formLisaPanel.pingAutoFill();
          } else {
            this.$refs.formLisaPanel.$refs?.lisaPanel?.$refs?.lisa?.handleToggle();
          }
      });
    },
    onLanguageSwitched(switchedLocale) {
      const locale = switchedLocale.slice(0, 2);

      // get new locale  data
      const localeData = this.languages.find(
        (locale) => locale.language === switchedLocale,
      );

      // get localized prefix
      const prefix = this.isPro ? localeData.prefixCorporate : '';

      // check if current page has an existing version in the switched locale
      const localizedPageData = this.altLanguagesData?.find(
        (data) => data.lang === switchedLocale,
      );

      if (!localizedPageData) {
        navigateTo({ path: `/${locale}/${prefix}` });
      } else {
        navigateTo({ path: this.switchLocalePath(locale) });
      }
    },
    openCookies() {
      window._axcb.push(function (sdk) {
        sdk.openCookies();
      });
    },
  },
  setup: (props) => {
    const i18n = useI18n();
    const route = useRoute();
    const locale = i18n.localeProperties;
    const { $enhancedLinkSerializer } = useNuxtApp();
    const { client: $prismic } = usePrismic();
    const store = useStore();

    useHead({
      htmlAttrs: {
        lang: locale.value.code,
      },
      bodyAttrs: {
        class: 'theme-green',
      },
    });

    return {
      i18n,
      route,
      enhancedLinkSerializer: $enhancedLinkSerializer,
      prismic: $prismic,
      store,
    };
  },
});
</script>

<style lang="scss">
body:lang(de),
body:lang(nl) {
  h1 {
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
  }
}

.__with-top-header {
  margin-top: var(--top-header-height);

  @media #{$mq-small} {
    margin-top: 0;
  }
}
</style>
