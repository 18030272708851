<template>
  <organisms-article-head v-if="sliceData" v-bind="sliceData" />
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, watch } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import articleHeadImageAdapter from '@/slices/ArticleHead/adapter';
import getJSONLD from '~/utils/structured-data';
import { useI18n, useRoute, useHead, useAsyncData } from '#imports';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const i18n = useI18n();
const route = useRoute();
const store = useStore();

const { data: sliceData } = await useAsyncData(
  `articleHead-${props.slice.id}`,
  async () => {
    return await articleHeadImageAdapter({ primary: props.context }, { i18n });
  },
);

const hasDate = computed(() => sliceData.value?.date);

watch(hasDate, (newHasDate) => {
  if (newHasDate) {
    const baseUrl = store.state.settings.root.baseUrl;
    const url = `${baseUrl}${route.path}`;

    useHead({
      script: [
        getJSONLD('newsarticle', { context: props, ...sliceData.value, url }),
      ],
    });
  }
});
</script>
