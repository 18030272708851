<template>
  <div v-if="!isNewLisa">
    <organisms-form-lisa-content
      v-if="sliceData"
      ref="lisa"
      v-bind="sliceData"
      :on-form-lisa-input="onFormLisaInput"
      :on-form-lisa-submit="onFormLisaSubmit"
    />
  </div>
  <div v-else>
    <lisa-content-module
      :id="slice?.primary?.module_lisa?.uid"
      :context="context ?? null"
      :slice="slice ?? null"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import lisaContentAdapter from '@/slices/LisaContent/adapter';
import useLisa from '@/composables/lisa';
import { useI18n, usePrismic, useAsyncData } from '#imports';
import { useTranslations } from '@/composables/componentTranslations';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const i18n = useI18n();
const { client: $prismic } = usePrismic();
const { fetchTranslations } = useTranslations();

const { setLisaData, lisaData, onFormLisaInput, onFormLisaSubmit } = useLisa({
  i18n,
});

const isNewLisa = computed(
  () => props.slice?.primary?.module_lisa?.data?.new_lisa,
);

const LisaContentModule = defineAsyncComponent(
  () => import('@/components/lisa-content-module'),
);

const { data: sliceData } = await useAsyncData(
  `lisaContent-${props.slice.id}`,
  async () => {
    if (isNewLisa.value) {
      await fetchTranslations('lisa', i18n);
      await setLisaData();

      return await lisaContentAdapter(props.slice, {
        prismic: $prismic,
        i18n,
        lisaData: lisaData.value,
      });
    }
    return null;
  },
  {
    watch: [isNewLisa],
  },
);
</script>
