import CiImg from '@/components/cloud-image/ci-img.vue';
import CiPicture from '@/components/cloud-image/ci-picture.vue';
import CiSource from '@/components/cloud-image/ci-source.vue';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('CiImg', CiImg);
  nuxtApp.vueApp.component('CiPicture', CiPicture);
  nuxtApp.vueApp.component('CiSource', CiSource);
});
