import { useStore } from 'vuex';
import linkSerializer from './link-serializer';
import { defineNuxtPlugin } from '#app';
import { useRoute } from '#imports';
import { isProRoute } from '@/utils/helpers/get-is-pro-route';

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  const isPro = isProRoute(route);
  const store = useStore();

  nuxtApp.provide('enhancedLinkSerializer', (link, isDocument) =>
    linkSerializer(link, isPro, isDocument, store),
  );
});
