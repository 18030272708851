import linkResolver from '~/plugins/prismic/link-resolver';

const getHrefLangDefaultLink = (defaultPageData, domain) => {
  if (!defaultPageData) return null;
  return domain
    ? domain + linkResolver(defaultPageData)
    : linkResolver(defaultPageData);
};

export default (data, defaultlocale, locale, url, domain, noIndexConfig) => {
  const hrefLangLinks = data.altLanguages?.length
    ? data.altLanguages.map((pageData) => {
        const localeCode = pageData.lang.slice(0, 2);
        return {
          hid: `href-lang-${localeCode}`,
          rel: 'alternate',
          hreflang: pageData.lang.slice(0, 2),
          href: domain
            ? domain + linkResolver(pageData)
            : linkResolver(pageData),
        };
      })
    : [];

  const href = domain ? domain + url : url;

  const hrefLangCurrentLink = {
    hid: `href-lang-${locale}`,
    rel: 'alternate',
    hreflang: locale,
    href,
  };

  const defaultPageData =
    data.altLanguages?.length &&
    data.altLanguages?.find(({ lang }) => lang.slice(0, 2) === defaultlocale);

  const hrefUrl = getHrefLangDefaultLink(defaultPageData, domain) || href;

  const hrefLangDefaultLink = hrefUrl
    ? [
        {
          hid: 'href-lang-default',
          rel: 'alternate',
          hreflang: 'x-default',
          href: hrefUrl,
        },
      ]
    : [];

  const head = {
    title: data.title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: data.description,
      },
      {
        hid: 'robots',
        name: 'robots',
        content: `${data.noindex || noIndexConfig ? 'noindex' : 'index'}, ${
          data.nofollow || noIndexConfig ? 'nofollow' : 'follow'
        }`,
      },
    ],
    link: [
      {
        rel: 'canonical',
        href:
          data?.canonicalUrl?.link_type === 'Web'
            ? data?.canonicalUrl?.url
            : href,
      },
      hrefLangCurrentLink,
      ...(!!hrefLangLinks && hrefLangLinks),
      ...(!!hrefLangDefaultLink && hrefLangDefaultLink),
    ],
  };
  head.meta.push(
    { hid: 'twitter:title', name: 'twitter:title', content: data.title },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: data.description,
    },
    { hid: 'twitter:image', name: 'twitter:image', content: data.image },
    { hid: 'og:locale', propety: 'og:locale', content: locale },
    { hid: 'og:title', property: 'og:title', content: data.title },
    { hid: 'og:site_name', property: 'og:site_name', content: data.siteName },
    {
      hid: 'og:description',
      property: 'og:description',
      content: data.description,
    },
    { hid: 'og:image', property: 'og:image', content: data.image },
    {
      hid: 'apple-mobile-web-app-title',
      name: 'apple-mobile-web-app-title',
      content: data.siteName,
    },
    {
      hid: 'application-name',
      name: 'application-name',
      content: data.siteName,
    },
  );

  if (url) {
    head.meta.push({ hid: 'og:url', property: 'og:url', content: url });
    head.meta.push({ hid: 'twitter:url', name: 'twitter:url', content: url });
  }
  return head;
};
