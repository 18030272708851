<template>
  <section class="products-list-v2 -small-gap">
    <products-filter :filters="filters" :handle-filter="handleFilter" />

    <div v-if="firstLoading" class="featured-block-container">
      <organisms-featured-block
        v-bind="sliceData"
        :loading="firstLoading"
        title="Loading"
      >
      </organisms-featured-block>
    </div>

    <div v-else-if="loading || Object.keys(orderedGroupedCards).length > 0">
      <div
        v-for="(group, groupKey) in orderedGroupedCards"
        :key="groupKey"
        class="featured-block-container"
      >
        <organisms-featured-block
          v-bind="sliceData"
          :loading="loading"
          :title="groupKey"
          :cards="loading ? null : group"
        >
        </organisms-featured-block>
      </div>
    </div>

    <div v-else class="no-result-container">
      <foundations-ui-wrapper
        v-if="notificationData"
        class="notification-warning"
      >
        <molecules-notification-card v-bind="notificationData" />
      </foundations-ui-wrapper>
      <form-component
        :slice="slice"
        :index="index"
        :slices="slices"
        :context="context"
      />
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import FormComponent from '@/slices/Form';
import ProductsFilter from '@/components/products-filter';
import { stripRichTextWrapperTag } from '@/utils/html';
import useLisaModule from '@/composables/lisaModule';
import productsFilterMixin from '~/mixins/productsFilter';
import { useI18n, useRoute, usePrismic } from '#imports';

export default {
  name: 'ProductsListPanelV2',
  components: { ProductsFilter, FormComponent },
  mixins: [productsFilterMixin],
  props: {
    slice: {
      type: Object,
      required: true,
    },
    index: {
      type: Object,
      required: true,
    },
    slices: {
      type: Array,
      required: true,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  setup: (props) => {
    const i18n = useI18n();
    const route = useRoute();
    const { initLisaModule, lisaModule } = useLisaModule({ i18n });
    const filters = ref(null);
    const { client: $prismic } = usePrismic();

    const fetchData = async () => {
      try {
        const urlParams = route.query;
        const autoFillWithContext =
          props.slice.primary?.lisa_context_autofill ?? true;

        await initLisaModule({
          useLisaTitle: false,
          resetButton: true,
          type: 'products',
          uid: props.slice.primary?.lisa_module.uid || 'part-lisa-common',
          defaultValues: {
            language: urlParams?.lang,
            destination: urlParams?.dest,
            coverType: urlParams?.type,
            location: urlParams?.loc,
          },
          autoFillWithContext,
          slice: props.slice,
        });

        const locationWithoutDefaultValue = {
          ...lisaModule?.value?.location,
          icon: 'control-location',
          defaultValue: null,
          value: null,
        };

        filters.value = {
          destination: lisaModule?.value?.destination,
          location: autoFillWithContext
            ? locationWithoutDefaultValue
            : lisaModule?.value?.location,
          language: lisaModule?.value?.language,
          coverType: lisaModule?.value?.coverType,
        };
      } catch (e) {
        console.error({ e });
      }
    };

    onMounted(() => {
      fetchData();
    });

    return { i18n, filters, prismic: $prismic };
  },
  data() {
    return {
      sliceData: {
        fixed: true,
        hasThreeColsGrid: true,
        withGrid: true,
        grayBackground: true,
        withBorderTop: false,
        searchErrorTitle: this.i18n.t('global.search-error-title'),
      },
      loading: true,
      firstLoading: true,
      lisaUid: null,
      orderedGroupedCards: {},
    };
  },
  computed: {
    notificationData() {
      return this.slice?.primary?.notification_message
        ? {
            type: 'warning',
            message: stripRichTextWrapperTag(
              this.slice?.primary?.notification_message,
            ),
            icon: 'map-pin',
          }
        : null;
    },
  },
  methods: {
    groupProducts(cards) {
      const coverTypes = this.filters.coverType.options.map(
        (option) => option.label,
      );

      const groupedCards = cards.reduce((acc, card) => {
        const group = card.data.coverType;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(card);

        return acc;
      }, {});

      const orderedGroupedCards = Object.keys(groupedCards)
        .sort((a, b) => {
          if (!coverTypes.includes(a)) return 1;
          if (!coverTypes.includes(b)) return -1;

          return coverTypes.indexOf(a) - coverTypes.indexOf(b);
        })
        .reduce((acc, coverType) => {
          if (groupedCards[coverType]) {
            acc[coverType] = groupedCards[coverType].sort((a, b) =>
              a.data.title.localeCompare(b.data.title),
            );
          }
          return acc;
        }, {});

      this.orderedGroupedCards = orderedGroupedCards;
    },
    async handleFilter(e) {
      this.loading = true;
      if (!Object.keys(this.orderedGroupedCards).length) {
        this.firstLoading = true;
      }

      try {
        const result = await this.onFiltersInput(e);
        this.groupProducts(result);
      } finally {
        this.loading = false;
        this.firstLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.products-list-v2 {
  background-color: $color-light-gray;
  margin-bottom: april-rem(-$spacing-4xl);

  @media #{$mq-medium} {
    margin-bottom: april-rem(-$spacing-5xl);
  }

  .products-filter {
    padding-bottom: april-rem($spacing-l);
    background-color: $color-white;
    border-bottom: 1px solid $color-medium-gray;
  }

  .featured-block-container:last-child {
    padding-bottom: april-rem($spacing-2xl);

    @media #{$mq-medium} {
      padding-bottom: april-rem(64);
    }
  }

  .featured-block {
    padding-bottom: 0;
    margin-top: april-rem($spacing-2xl);
    border: 0;

    @media #{$mq-medium} {
      margin-top: april-rem(64);
    }
  }

  .no-result-container {
    padding-bottom: april-rem($spacing-4xl);
    margin-top: april-rem($spacing-l);
    background-color: $color-light-gray;
    padding-top: april-rem($spacing-2xl);

    .form-push {
      margin-top: april-rem(75);
      .form-push-head {
        padding-top: 0;
      }
      .form-push-card {
        background-color: $color-white;
      }
    }
  }
}
</style>
