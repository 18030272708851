<template>
  <organisms-top-header
    v-if="topHeader?.text"
    ref="topHeaderRef"
    :class="[isHidden && 'hidden']"
    :text="topHeader.text"
    :link="topHeader.link"
    @close="handleClose"
  />
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { LAYOUT_SET_TOP_HEADER } from '@/store/mutationTypes';

export default {
  setup: () => {
    const store = useStore();
    const topHeaderRef = ref(null);

    const topHeader = computed(() => store.state.layout.topHeader);
    const isHidden = computed(() => topHeader.value?.isHidden || false);

    onMounted(() => {
      if (topHeader.value) {
        store.commit(`layout/${LAYOUT_SET_TOP_HEADER}`, {
          ...topHeader.value,
          height: topHeaderRef.value?.$el?.clientHeight || 0,
        });
      }
    });

    const handleClose = () => {
      store.commit(`layout/${LAYOUT_SET_TOP_HEADER}`, {
        ...topHeader.value,
        isHidden: true,
        height: 0,
      });
    };

    return {
      topHeader,
      isHidden,
      handleClose,
      topHeaderRef,
    };
  },
};
</script>

<style lang="scss">
.top-header {
  &.hidden {
    display: none;
  }
}
</style>
