import { ref } from 'vue';
import { useStore } from 'vuex';
import { useGtm } from '@gtm-support/vue-gtm';
import { usePrismic, useNuxtApp, navigateTo } from '#imports';
import { pushLisaModule } from '@/plugins/gtm.client';
import lisaModuleAdapter, {
  getDefaultLisaLabels,
  queryProduct,
  LISA_TYPE,
  PRODUCT_QUERY_ERROR,
  LISA_CTA,
} from '~/slices/LisaContent/adapter.new';
import { getFiltersArrays } from '~/utils/helpers/get-filters';

export default ({ i18n }) => {
  const { $enhancedLinkSerializer } = useNuxtApp();

  const lang = i18n.localeProperties.value.language;
  const lisaModule = ref(getDefaultLisaLabels(i18n));
  const sliceData = ref(null);
  const lisaData = ref(null);
  const type = ref('');
  const uid = ref(null);
  const slice = ref(null);
  const useLisaTitle = ref(false);
  const resetButton = ref(false);
  const selectedCoverType = ref(null);
  const selectedDestination = ref(null);
  const selectedLanguage = ref(null);
  const selectedLocation = ref(null);
  const pageContext = ref(null);
  const store = useStore();
  const $gtm = useGtm();
  const { client: $prismic } = usePrismic();

  // const temporaryTitle = [
  //   {
  //     type: 'paragraph',
  //     text: 'Find the right insurance for you:',
  //     spans: [
  //       {
  //         type: 'label',
  //         data: {
  //           label: 'black-color',
  //         },
  //       },
  //     ],
  //   },
  // ];

  // const title = ref(stripRichTextWrapperTag(temporaryTitle));

  /**
   * Process GTM tracking for Lisa module
   */
  const processTracking = (result) => {
    const resultType = result ? 'product page' : 'except to form';
    pushLisaModule(
      { store, $gtm },
      {
        lisaModule,
        destination: selectedDestination.value,
        coverType: selectedCoverType.value,
        language: selectedLanguage.value,
        location: selectedLocation.value,
        resultType,
      },
    );
  };

  /**
   * Redirect to a specific path
   */
  const redirect = (path) => {
    const isPathExternal = path.includes('http');
    if (isPathExternal) {
      window.location.href = path;
    } else {
      navigateTo({ path });
    }
  };

  /**
   * Update buttons state
   */
  const updateButtonsState = (status, isTrue, isQuote = false) => {
    const isLisaPanelOrContent = [LISA_TYPE.panel, LISA_TYPE.content].includes(
      type.value,
    );
    const hasSubmitCta = isLisaPanelOrContent && lisaModule.value.submitCta;
    const hasQuoteCta = isLisaPanelOrContent && lisaModule.value.cta;
    const hasButton = lisaModule.value.buttons.length > 0;

    if (status === 'loading') {
      if (hasQuoteCta && isQuote) {
        lisaModule.value.cta.loading = isTrue;
        return;
      }
      if (hasButton) {
        lisaModule.value.buttons[0].cta.loading = isTrue;
      }
      if (hasSubmitCta) {
        lisaModule.value.submitCta.loading = isTrue;
      }
      return;
    }
    if (status === 'enable') {
      if (hasSubmitCta) lisaModule.value.submitCta.disabled = !isTrue;
      if (hasQuoteCta) lisaModule.value.cta.disabled = !isTrue;
      if (hasButton) {
        lisaModule.value.buttons[0].cta.disabled = !isTrue;
      }
    }
  };

  /**
   * Save last destination to local storage
   */
  const saveLastDestination = (destination) => {
    localStorage.lastResearchOption = JSON.stringify(destination);
  };

  /**
   * Handle Lisa module submit
   */
  const onLisaModuleSubmit = async (event, isQuote) => {
    updateButtonsState('loading', true, isQuote);
    saveLastDestination(selectedDestination.value);

    const productFound = await queryProduct({
      selectedCoverType: selectedCoverType.value,
      selectedDestination: selectedDestination.value,
      selectedLanguage: selectedLanguage.value,
      selectedLocation: selectedLocation.value,
      lang,
      isPro: pageContext.value?.isPro,
      prismic: $prismic,
    });

    const locale = i18n.localeProperties.value.code;
    const productHasError = Object.values(PRODUCT_QUERY_ERROR).includes(
      productFound?.error,
    );

    if (productHasError) {
      const errorPageUID = lisaModule.value.errorPage?.uid ?? '404';
      const errorPageURL = `${locale}/landing/${errorPageUID}`;
      navigateTo({ path: `/${errorPageURL}` });
      return;
    }

    processTracking(productFound);

    if (!productFound) {
      const uid = lisaModule.value.noResultPage?.uid;
      const noResultPage = uid ? `landing/${uid}` : '404';
      const landingPage = `${locale}/${noResultPage}`;
      navigateTo({ path: `/${landingPage}` });
      resetLisa();
      return;
    }

    if (isQuote) {
      const link = $enhancedLinkSerializer(
        productFound.data.card[0].cta_primary_link,
      );
      redirect(link.href);
    } else {
      const offer = productFound.data.offer.uid;
      const product = productFound.uid;
      navigateTo({ path: `/${locale}/${offer}/${product}` });
    }
    resetLisa();
  };

  /**
   * Handle Lisa module input
   */
  const onLisaModuleInput = (event) => {
    selectedCoverType.value = event.selectedCoverType;
    selectedDestination.value = event.selectedDestination;
    selectedLanguage.value = event.selectedLanguage;
    selectedLocation.value = lisaModule.value.location;

    const shouldEnableSubmit =
      selectedCoverType.value &&
      selectedDestination.value &&
      selectedLanguage.value;
    updateButtonsState('enable', shouldEnableSubmit);
  };

  /**
   * Handle Lisa module quote
   */
  const onLisaModuleQuote = (event) => {
    onLisaModuleSubmit(event, true);
  };

  /**
   * Reset Lisa module
   */
  const resetLisa = async () => {
    const isLisaPanelOrContent = [LISA_TYPE.panel, LISA_TYPE.content].includes(
      type.value,
    );
    if (isLisaPanelOrContent) {
      updateButtonsState('loading', false);
      updateButtonsState('enable', false);
    }
    lisaModule.value.coverType.value = {};
    lisaModule.value.language.value = {};
    lisaModule.value.destination.value = '';

    await initLisaModule({
      useLisaTitle: useLisaTitle.value,
      resetButton: resetButton.value,
      type: type.value,
      uid: uid.value,
      context: pageContext.value,
      slice: slice.value,
    });
  };

  /**
   * Set Lisa data
   */
  const setLisaData = () => {
    lisaData.value = getFiltersArrays({ i18n });
  };

  /**
   * Auto fill Lisa module dropdowns
   */
  const autoFillLisaOptions = (lisa, fields = {}, reset = false, type) => {
    const { location, coverType, destination, language } = fields;

    if (!lisa) return;

    const lisaClone = {};

    lisaClone.buttons = lisa.buttons;
    lisaClone.coverType = lisa.coverType;
    lisaClone.destination = lisa.destination;
    lisaClone.language = lisa.language; // TODO nuxt 3 : do a structured clone ??
    lisaClone.location = lisa.location;
    lisaClone.image = lisa.image;
    lisaClone.noResultPage = lisa.noResultPage;
    lisaClone.submitCta = lisa.submitCta;
    lisaClone.title = lisa.title;
    lisaClone.cta = lisa.cta;

    if (reset) {
      if (type === 'products') {
        lisaClone.location.defaultValue = null;
        lisaClone.location.value = null;
      }
      lisaClone.destination.defaultValue = null;
      lisaClone.destination.value = null;
      lisaClone.coverType.value = null;
      lisaClone.language.value = null;
    }

    if (location) {
      const foundLocation = lisaClone.location.options.find((opt) =>
        opt.value.startsWith(location.toUpperCase()),
      );
      lisaClone.location = {
        ...lisaClone.location,
        defaultValue: foundLocation,
        value: foundLocation.value,
      };
    }

    if (destination) {
      const foundDestination = lisaClone.destination.options.find((opt) =>
        opt.value.startsWith(destination.toUpperCase()),
      );
      lisaClone.destination = {
        ...lisaClone.destination,
        defaultValue: foundDestination,
        value: foundDestination,
      };
    }

    if (coverType) {
      const foundCoverType = lisaClone.coverType.options.find(
        (opt) => opt.value.toUpperCase() === coverType.toUpperCase(),
      );
      lisaClone.coverType.value = foundCoverType;
    }

    if (language) {
      const foundLanguage = lisaClone.language.options.find(
        (opt) => opt.code.toUpperCase() === language.toUpperCase(),
      );
      lisaClone.language.value = foundLanguage;
    }

    return lisaClone;
  };

  /**
   * Auto fill Lisa module with default values
   */
  const autoFill = (lisa, defaultValues = {}, withContext = true, type) => {
    const contextDefaultValues = {
      // TODO: Prismic is currently using 3 letters country code, this is a temporary
      // hack which will work for some codes until the data is changed in the CMS
      destination:
        pageContext.value?.country_code?.length >= 2
          ? pageContext.value.country_code.substring(0, 2)
          : null,
      coverType: pageContext.value?.cover_type?.id,
      language: i18n.localeProperties.value.code,
    };

    return autoFillLisaOptions(
      lisa,
      withContext ? contextDefaultValues : defaultValues,
      !withContext,
      type,
    );
  };

  const initLisaModule = async (options) => {
    const {
      useLisaTitle: useLisaTitleOption = false,
      resetButton: resetButtonOption = false,
      type: typeOption = LISA_TYPE.hero,
      uid: uidOption = null,
      context: contextOption = null,
      slice: sliceOption = null,
      cta: ctaOption = LISA_CTA.PLAN,
      defaultValues = {},
      autoFillWithContext = true,
    } = options;

    uid.value = uidOption;
    type.value = typeOption;
    useLisaTitle.value = useLisaTitleOption;
    resetButton.value = resetButtonOption;
    pageContext.value = contextOption;
    slice.value = sliceOption;

    setLisaData();

    const lisa = await lisaModuleAdapter(slice, {
      uid: uid.value,
      type: type.value,
      prismic: $prismic,
      i18n,
      lisaData: lisaData.value,
      useLisaTitle: useLisaTitle.value,
      enableResetButton: resetButton.value,
      ctaOption,
    });

    lisaModule.value = autoFill(
      lisa,
      defaultValues,
      autoFillWithContext ?? true,
      type.value,
    );
  };

  return {
    lisaModule,
    sliceData,
    lisaData,
    useLisaTitle,
    resetButton,
    selectedCoverType,
    selectedDestination,
    selectedLanguage,
    selectedLocation,
    processTracking,
    updateButtonsState,
    saveLastDestination,
    onLisaModuleSubmit,
    onLisaModuleInput,
    onLisaModuleQuote,
    resetLisa,
    setLisaData,
    autoFillLisaOptions,
    autoFill,
    initLisaModule,
  };
};
