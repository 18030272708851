import { productThumb, cardThumb } from '@/utils/adapters/product';
import getContact from '@/utils/adapters/contact';
import getContactList from '@/utils/adapters/contactList';

function getCards(data, $enhancedLinkSerializer) {
  const cards = [];

  if (data.nav_products.length) {
    data.nav_products.forEach(({ product }) => {
      if (product) {
        const itemData =
          product.type === 'module_card'
            ? cardThumb(product, $enhancedLinkSerializer)
            : productThumb(product, $enhancedLinkSerializer);

        if (itemData) {
          cards.push({
            type: 'product-thumb',
            data: {
              ...itemData,
              heading: 'p',
            },
          });
        }
      }
    });
  }
  return cards;
}

function getContactData(data, $enhancedLinkSerializer) {
  const singleContact = data?.contact?.data;
  let contact, contactCta, contactList;
  const withoutIcon = !!data.contact_no_icon;

  if (singleContact) {
    contact = getContact(singleContact);
    contact.icon = !withoutIcon ? 'control-call' : null;
    contact.subhead = null;
    contactCta = {
      link: $enhancedLinkSerializer(data.contact_cta_link),
      label: data.contact_cta_label,
    };

    if (data.contact_title) {
      contact.title = data.contact_title;
    }
  } else if (data?.contact_list?.length) {
    contactList = data ? getContactList(data, $enhancedLinkSerializer) : null;
  }
  return {
    contact,
    contactCta,
    contactList,
  };
}

function getButtons(data, $enhancedLinkSerializer) {
  const buttons = [];

  if (data.nav_ctas.length) {
    data.nav_ctas.forEach((cta) => {
      const ctaLink = $enhancedLinkSerializer(cta.nav_cta_link);
      if (ctaLink) {
        buttons.push({
          label: cta.nav_cta_label,
          cta: {
            ...ctaLink,
            variant: cta.nav_cta_variant,
          },
        });
      }
    });
  }
  return buttons;
}
function getSeeAll(entryLink, title) {
  if (!entryLink) return;
  return {
    link: entryLink,
    label: title,
  };
}

function getEntry({ data, entryLink, seeAllLabel }, $enhancedLinkSerializer) {
  // Buttons
  const buttons = getButtons(data, $enhancedLinkSerializer);

  // Contact
  const contact = getContactData(data, $enhancedLinkSerializer);

  // Product cards
  const cards = getCards(data, $enhancedLinkSerializer);

  // Total
  const seeAll = getSeeAll(entryLink, seeAllLabel);

  // single Aside Title
  const asideTitle = data.nav_aside_title;

  // Single Aside Link
  const singleAsideLink = data.aside_link && data.aside_link[0];

  // Aside Link
  const asideLink = singleAsideLink && {
    label: singleAsideLink.label,
    icon: singleAsideLink.icon,
    link: $enhancedLinkSerializer(singleAsideLink.link),
  };

  // Title
  const title = data.nav_products_hide_label_count
    ? data.nav_products_label
    : `${data.nav_products_label} <small>(${data.nav_products.length})</small>`;

  return {
    asideTitle,
    buttons,
    ...contact,
    cards,
    seeAll,
    title,
    asideLink,
  };
}

function navSanitizer({ i18n, route, $enhancedLinkSerializer }, datas) {
  const {
    data: { slices: data = [] },
    modules,
  } = datas;

  const navSlices = ['nav_item', 'nav_tabs'];
  const navData = data.filter((slice) => navSlices.includes(slice.slice_type));
  return navData?.map((sliceData) => {
    const { slice_type: sliceType, primary, items = [] } = sliceData;

    let content = {};
    let link;

    const label = primary.nav_entry_label;
    const seeAllLabel = primary.nav_entry_see_all_label;
    const uid = primary?.nav_entry_link?.uid;

    const routeUid = route?.params?.market;
    const current = uid && uid === routeUid;

    // Link

    const entryLinkTitle = primary?.nav_entry_link?.data?.title;
    const entryLink = $enhancedLinkSerializer(primary.nav_entry_link);

    if (!modules) content = {};
    // Simple panel
    else if (sliceType === 'nav_item') {
      const id = primary?.navigation_module?.id;
      const navData = modules[id];

      if (entryLink && !navData) {
        link = entryLink;
      } else {
        content = {
          type: 'category',
          data: getEntry(
            { ...navData, entryLink, entryLinkTitle, seeAllLabel },
            $enhancedLinkSerializer,
          ),
        };
      }
    }

    // Panel with tabs
    else if (sliceType === 'nav_tabs') {
      let seeAll;
      const tabs = [];
      items.forEach((item) => {
        const id = item?.navigation_module?.id;
        const navData = modules[id];
        // See all link
        if (entryLink) {
          seeAll = getSeeAll(entryLink, seeAllLabel);
        }
        if (!item.tab_label || !navData) {
          return;
        }
        tabs.push({
          name: item.tab_label,
          content: getEntry({ ...navData }, $enhancedLinkSerializer),
        });
      });

      content = {
        type: 'tabs',
        seeAll,
        data: tabs,
      };
    }

    return {
      uid,
      current,
      label,
      content,
      link,
    };
  });
}

export default navSanitizer;
