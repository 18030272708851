<template>
  <organisms-ratings-push v-if="sliceData" v-bind="sliceData" />
</template>

<script>
import { ref } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import ratingPushPushAdapter from '@/slices/RatingPush/adapter';
import { useI18n, useNuxtApp } from '#imports';

export default {
  name: 'RatingPush',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup: (props) => {
    const sliceData = ref(null);
    const { $enhancedLinkSerializer } = useNuxtApp();
    const i18n = useI18n();

    sliceData.value = ratingPushPushAdapter(props.slice, {
      i18n,
      enhancedLinkSerializer: $enhancedLinkSerializer,
    });

    return { sliceData };
  },
};
</script>
