import { stripRichTextWrapperTag } from '@/utils/html';

export default (data, linkSerializer) => {
  const filteredContacts = data.contact_list?.filter(
    ({ contact }) => !!contact,
  );

  if (!filteredContacts?.length) {
    return null;
  }

  const contactTitle = stripRichTextWrapperTag(data.contact_title);
  const contactMention = stripRichTextWrapperTag(data.contact_mention);

  const contacts = filteredContacts.map(({ contact }) => {
    const { data: contactData } = contact;

    return {
      title: contactData.contact_subhead,
      icon: 'control-call',
      number: {
        label:
          contactData.contact_phone_label || contactData.contact_phone_number,
        tel: contactData.contact_phone_number,
      },
    };
  });

  return {
    title: contactTitle,
    contacts,
    mention: contactMention,
    cta: {
      label: data.contact_cta_label,
      link: linkSerializer(data.contact_cta_link),
    },
  };
};
