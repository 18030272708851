import { stripRichTextWrapperTag } from '@/utils/html';
import { routerLinks } from '@/utils/fetch-links';

const getFormulas = (formulas, { locale, currency }) => {
  const formater = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || 'EUR',
    currencyDisplay: ['EUR', 'GBP'].includes(currency)
      ? 'narrowSymbol'
      : 'code',
  });

  return formulas.map(
    ({
      name,
      rest_share: restShare,
      april_share: aprilShare,
      april_share_display: aprilShareDisplay = false,
      third_party_share: thirdPartyShare,
      third_party_share_display: thirdPartyShareDisplay = false,
    }) => {
      let rest, april, thirdParty;

      if (!restShare && aprilShare | thirdPartyShare) {
        rest = {
          label: formater.format(0),
          value: 0.01,
        };
      } else {
        rest = {
          label: formater.format(restShare),
          value: restShare,
        };
      }

      if (aprilShare) {
        april = {
          label: formater.format(aprilShare),
          value: aprilShare,
        };
      } else if (
        (!aprilShare && !aprilShareDisplay) | (!aprilShare && thirdPartyShare)
      ) {
        april = {
          label: formater.format(0),
          value: 0.01,
        };
      }

      if (thirdPartyShare) {
        thirdParty = {
          label: formater.format(thirdPartyShare),
          value: thirdPartyShare,
        };
      } else if (!thirdPartyShare && !thirdPartyShareDisplay) {
        thirdParty = {
          label: formater.format(0),
          value: 0.01,
        };
      }

      return {
        title: name,
        shares: {
          rest,
          april,
          thirdParty,
        },
      };
    },
  );
};

const getExamples = async (examples, i18n, prismic, locale) => {
  const examplesResult = [];
  for (const example of examples) {
    const lang = i18n.localeProperties.value.language;
    const dataVizExample = await prismic.getByID(example.data_viz_example.id, {
      fetchLinks: routerLinks,
      lang,
    });

    if (dataVizExample && dataVizExample.data) {
      const { title, description, currency, formulas } = dataVizExample.data;
      const items = getFormulas(formulas, { locale, currency });

      const maxTotal = items.reduce((value, formula) => {
        const { rest, april, thirdParty } = formula.shares;

        const formulaTotal =
          (rest?.value || 0) + (april?.value || 0) + (thirdParty?.value || 0);

        return formulaTotal > value ? formulaTotal : value;
      }, 0);

      examplesResult.push({
        name: title,
        description: stripRichTextWrapperTag(description),
        graph: {
          shouldFormat: false,
          maxTotal,
          items,
        },
      });
    }
  }

  return examplesResult;
};

export default async function (
  { primary, items },
  { i18n, prismic, enhancedLinkSerializer, locale },
) {
  const {
    data_viz_title: dataVizTitle,
    data_viz_select_label: dataVizSelectLabel,
    data_viz_legend_rest: dataVizLegendRest,
    data_viz_legend_april: dataVizLegendApril,
    data_viz_legend_third_party: dataVizLegendThirdParty,
    data_viz_legal_mention: dataVizLegalMention,
    data_viz_primary_cta_link: dataVizPrimaryCtaLink,
    data_viz_primary_cta_label: dataVizPrimaryCtaLabel,
    data_viz_secondary_cta_link: dataVizSecondaryCtaLink,
    data_viz_secondary_cta_label: dataVizSecondaryCtaLabel,
  } = primary;

  const ctas = [];
  const primaryLink = enhancedLinkSerializer(dataVizPrimaryCtaLink);
  const secondaryLink = enhancedLinkSerializer(dataVizSecondaryCtaLink);

  if (primaryLink && dataVizPrimaryCtaLabel) {
    ctas.push({
      ...primaryLink,
      label: dataVizPrimaryCtaLabel,
    });
  }

  if (secondaryLink && dataVizSecondaryCtaLabel) {
    ctas.push({
      ...secondaryLink,
      label: dataVizSecondaryCtaLabel,
      variant: 'secondary',
    });
  }

  const examples = await getExamples(items, i18n, prismic, locale);

  return {
    title: stripRichTextWrapperTag(dataVizTitle),
    legend: {
      rest: dataVizLegendRest,
      april: dataVizLegendApril,
      thirdParty: dataVizLegendThirdParty,
    },
    legalMention: stripRichTextWrapperTag(dataVizLegalMention),
    selectLabel: dataVizSelectLabel || i18n.t('dataviz.select-label'),
    ctas,
    examples,
  };
}
