<template>
  <section class="products-list-v1 -small-gap">
    <organisms-filter-list
      v-if="filtersData"
      v-bind="filtersData"
      @refine="setfilters"
    />

    <div v-if="firstLoading" class="featured-block-container">
      <organisms-featured-block v-bind="loadingData" title="Loading">
      </organisms-featured-block>
    </div>

    <div v-else-if="loading || Object.keys(groupedCards).length > 0">
      <div
        v-for="(group, groupKey) in groupedCards"
        :key="groupKey"
        class="featured-block-container"
      >
        <organisms-featured-block
          v-bind="sliceData"
          :loading="loading"
          :title="groupKey"
          :cards="loading ? null : group"
        >
          <template #notification>
            <ui-wrapper>
              <molecules-notification-card
                v-if="notificationData.message"
                v-bind="notificationData"
              />
            </ui-wrapper>
          </template>
          <template #pagination>
            <molecules-ui-pagination
              v-if="sliceData?.withPagination && sliceData?.totalPages > 1"
              :pages="sliceData?.totalPages"
            />
          </template>
        </organisms-featured-block>
      </div>
    </div>

    <div v-else-if="loadingError" class="featured-block-container">
      <organisms-search-error-message :title="searchErrorTitle" />
    </div>

    <div v-else class="featured-block-container">
      <organisms-search-no-result :title="sliceData?.noResultTitle" />>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import productsListAdapter from '@/slices/ProductsList/adapter';
import getFilters from '@/utils/helpers/get-filters';
import { useI18n } from '#imports';
const UiWrapper = defineAsyncComponent(() => import('Foundations/ui-wrapper'));

export default {
  name: 'ProductsListPanelV1',
  components: { UiWrapper },
  props: {
    slice: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    slices: {
      type: Array,
      required: true,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  setup: (props) => {
    const i18n = useI18n();

    return { i18n };
  },
  data() {
    return {
      sliceData: null,
      groupedCards: {},
      filtersValues: [],
      loading: true,
      firstLoading: true,
      loadingError: false,
      searchErrorTitle: this.i18n.t('global.search-error-title'),
      loadingData: {
        hasThreeColsGrid: true,
        withGrid: true,
        fixed: true,
        loading: true,
        grayBackground: true,
        withBorderTop: false,
      },
    };
  },
  async fetch() {
    if (!this.sliceData?.cards?.length) {
      this.firstLoading = true;
    }
    this.loading = true;
    this.loadingError = false;
    try {
      const coverTypes =
        this.filtersData.selects[2].options.map((option) => option.label) || [];

      const data = await this.queryProducts();
      const cards = data?.cards || [];

      const groupedCards = cards.reduce((acc, card) => {
        const group = card.data.coverType;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(card);
        return acc;
      }, {});

      const orderedGroupedCards = Object.keys(groupedCards)
        .sort((a, b) => {
          if (!coverTypes.includes(a)) return 1;
          if (!coverTypes.includes(b)) return -1;

          return coverTypes.indexOf(a) - coverTypes.indexOf(b);
        })
        .reduce((acc, coverType) => {
          if (groupedCards[coverType]) {
            acc[coverType] = groupedCards[coverType].sort((a, b) =>
              a.data.title.localeCompare(b.data.title),
            );
          }
          return acc;
        }, {});

      this.groupedCards = orderedGroupedCards;

      this.sliceData = {
        ...data,
        grayBackground: true,
      };
    } catch {
      this.loadingError = true;
    } finally {
      this.loadingError = true;
      this.loading = false;
      this.firstLoading = false;
    }
  },
  computed: {
    filtersData() {
      return getFilters({
        title: this.sliceData?.listTitle || '',
        i18n: this.i18n,
      });
    },
    notificationData() {
      return {
        type: 'warning',
        message: this.sliceData?.notificationMessage,
        icon: 'control-warning',
      };
    },
  },
  watch: {
    '$route.query': '$fetch',
    filtersValues: '$fetch',
  },
  methods: {
    async queryProducts() {
      const page = parseInt(this.$route.query.page) || 1;
      const data = await productsListAdapter(this.slice, {
        page,
        i18n: this.i18n,
        prismic: this.$prismic,
        enhancedLinkSerializer: this.$enhancedLinkSerializer,
        isPro: this.context.isPro,
        filters: this.filtersValues,
      });

      return data;
    },
    setfilters(filters) {
      this.filtersValues = filters;
    },
  },
};
</script>
<style lang="scss">
.products-list-v1 {
  background-color: $color-light-gray;
  margin-bottom: april-rem(-$spacing-4xl);

  @media #{$mq-medium} {
    margin-bottom: april-rem(-$spacing-5xl);
  }

  .filter-list {
    padding-bottom: april-rem($spacing-l);
    background-color: $color-white;
    border-bottom: 1px solid $color-medium-gray;
  }

  .featured-block-container:last-child {
    padding-bottom: april-rem($spacing-2xl);

    @media #{$mq-medium} {
      padding-bottom: april-rem(64);
    }
  }

  .search-error-message,
  .search-no-result {
    margin-top: april-rem($spacing-4xl);
    margin-bottom: april-rem($spacing-4xl);
  }

  .featured-block {
    padding-bottom: 0;
    margin-top: april-rem($spacing-2xl);

    @media #{$mq-medium} {
      margin-top: april-rem(64);
    }
  }
}
</style>
