// Code generated by Slice Machine. DO NOT EDIT.

import { defineAsyncComponent } from 'vue';
import { defineSliceZoneComponents } from '@prismicio/vue';

export const components = defineSliceZoneComponents({
  accordion_push: defineAsyncComponent(
    () => import('./AccordionPush/index.vue'),
  ),
  advantages_push: defineAsyncComponent(
    () => import('./AdvantagesPush/index.vue'),
  ),
  all_guides: defineAsyncComponent(() => import('./AllGuides/index.vue')),
  article_head: defineAsyncComponent(() => import('./ArticleHead/index.vue')),
  banner_push: defineAsyncComponent(() => import('./BannerPush/index.vue')),
  breadcrumbs: defineAsyncComponent(() => import('./Breadcrumbs/index.vue')),
  cards_list: defineAsyncComponent(() => import('./CardsList/index.vue')),
  contact_configurator: defineAsyncComponent(
    () => import('./ContactConfigurator/index.vue'),
  ),
  contact_push: defineAsyncComponent(() => import('./ContactPush/index.vue')),
  cover_types: defineAsyncComponent(() => import('./CoverTypes/index.vue')),
  ctas_list: defineAsyncComponent(() => import('./CtasList/index.vue')),
  data_viz: defineAsyncComponent(() => import('./DataViz/index.vue')),
  documents_list: defineAsyncComponent(
    () => import('./DocumentsList/index.vue'),
  ),
  edit_rich_text: defineAsyncComponent(
    () => import('./EditoRichText/index.vue'),
  ),
  edito_carousel: defineAsyncComponent(
    () => import('./EditoCarousel/index.vue'),
  ),
  edito_cta: defineAsyncComponent(() => import('./EditoCta/index.vue')),
  edito_image: defineAsyncComponent(() => import('./EditoImage/index.vue')),
  edito_table: defineAsyncComponent(() => import('./EditoTable/index.vue')),
  edito_table_row: defineAsyncComponent(
    () => import('./EditoTableRow/index.vue'),
  ),
  featured_block: defineAsyncComponent(
    () => import('./FeaturedBlock/index.vue'),
  ),
  form: defineAsyncComponent(() => import('./Form/index.vue')),
  guides: defineAsyncComponent(() => import('./Guides/index.vue')),
  hero: defineAsyncComponent(() => import('./Hero/index.vue')),
  legal_mentions: defineAsyncComponent(
    () => import('./LegalMentions/index.vue'),
  ),
  lisa_content: defineAsyncComponent(() => import('./LisaContent/index.vue')),
  mosaic_push: defineAsyncComponent(() => import('./MosaicPush/index.vue')),
  nav_item: defineAsyncComponent(() => import('./NavItem/index.vue')),
  nav_tabs: defineAsyncComponent(() => import('./NavTabs/index.vue')),
  picto_carousel: defineAsyncComponent(
    () => import('./PictoCarousel/index.vue'),
  ),
  products_list: defineAsyncComponent(() => import('./ProductsList/index.vue')),
  quote: defineAsyncComponent(() => import('./Quote/index.vue')),
  rating_push: defineAsyncComponent(() => import('./RatingPush/index.vue')),
  related_guides: defineAsyncComponent(
    () => import('./RelatedGuides/index.vue'),
  ),
  seo_push: defineAsyncComponent(() => import('./SeoPush/index.vue')),
  taxonomy_guides: defineAsyncComponent(
    () => import('./TaxonomyGuides/index.vue'),
  ),
  top_header: defineAsyncComponent(() => import('./TopHeader/index.vue')),
  youtube_video: defineAsyncComponent(() => import('./YoutubeVideo/index.vue')),
});
