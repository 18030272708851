import { richText } from '../../utils/html';

export default ({ primary }, { enhancedLinkSerializer }) => {
  if (!primary.top_header_module?.data) {
    return null;
  }

  const { data } = primary.top_header_module;

  return {
    text: richText(data.text),
    link: {
      link: enhancedLinkSerializer(data.link),
      label: data.link_label,
    },
  };
};
