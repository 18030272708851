<template>
  <organisms-products-filter
    :with-wrapper="true"
    :cover-type="filters?.coverType || {}"
    :destination="filters?.destination || {}"
    :location="filters?.location || {}"
    :language="filters?.language || {}"
    :on-filter-input="handleFilter"
  />
</template>

<script>
export default {
  name: 'ProductsFilter',
  props: {
    filters: {
      type: Object,
      default: null,
    },
    handleFilter: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
