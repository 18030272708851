<template>
  <organisms-picto-carousel v-if="sliceData" v-bind="sliceData" />
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue';
import { useI18n } from 'vue-i18n';
import pictoCarouselAdapter from '@/slices/PictoCarousel/adapter';

export default {
  name: 'PictoCarousel',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup: () => {
    const i18n = useI18n();
    return {
      i18n2: i18n,
    };
  },
  computed: {
    sliceData() {
      return pictoCarouselAdapter(this.slice, {
        i18n: this.i18n2,
        enhancedLinkSerializer: this.$enhancedLinkSerializer,
      });
    },
  },
};
</script>
