<template>
  <ProductsListPanelV2
    v-if="slice?.primary?.new_products_list"
    :slice="slice"
    :index="index"
    :slices="slices"
    :context="context"
  />
  <ProductsListPanelV1
    v-else
    :slice="slice"
    :index="index"
    :slices="slices"
    :context="context"
  />
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue';
import ProductsListPanelV1 from '@/components/products-list-panel-v1';
import ProductsListPanelV2 from '@/components/products-list-panel-v2';

export default {
  name: 'ProductsList',
  components: { ProductsListPanelV1, ProductsListPanelV2 },
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
};
</script>
