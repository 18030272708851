import { stripHTMLTags } from '@/utils/html';
import linkResolver from '@/plugins/prismic/link-resolver';

const getOrganizationJsonLd = (data, headData) => {
  const jsonldData = {
    '@type': 'Organization',
    name: headData.title,
    url: data.url,
    logo: data.organization_logo,
    sameAs: data.social_profiles,
  };
  if (data.reviews?.average_rating && data.reviews?.review_count) {
    jsonldData.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: data.reviews.average_rating,
      reviewCount: data.reviews.review_count,
    };
  }
  return jsonldData;
};
const getOfferJsonLd = (data, headData) => {
  return {
    '@type': 'Product',
    url: data.url,
    name: data.offer?.data?.breadcrumb_title || data.offer?.data?.title,
    description: headData.description,
  };
};

const getFaqJsonLd = (data) => {
  return {
    '@type': 'FAQPage',
    mainEntity: data.items.map(({ head, text }) => ({
      '@type': 'Question',
      name: stripHTMLTags(head),
      acceptedAnswer: {
        '@type': 'Answer',
        text: stripHTMLTags(text),
      },
    })),
  };
};

const getBreadcrumbJsonLd = (data) => {
  return {
    '@type': 'BreadcrumbList',
    itemListElement: data.links?.map((link, index) => {
      let path;

      if (link?.cta?.field) {
        path = linkResolver(link.cta.field);
      } else if (link?.cta?.href) {
        path = link.cta.href;
      } else {
        path = data.currentUrl;
      }

      const linkItem =
        data.baseUrl && path
          ? {
              '@id': `${data.baseUrl}${path}`,
            }
          : {};

      return {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          name: link.label,
          ...linkItem,
        },
      };
    }),
  };
};

const getArticleJsonLd = (data) => {
  const date = new Date(data.context.context.date);
  return {
    '@type': 'NewsArticle',
    url: data.url,
    mainEntityOfPage: data.url,
    headline: data.title,
    articleBody: data.description,
    image: [data.image],
    datePublished: date.toISOString(),
  };
};

export default (type, data) => {
  let jsonldData = {};
  const layout = data.context?.$store?.state?.layout || {};
  const headData = {
    title: data.context?.$i18n.t('configs.head.title'),
    description: data.context?.$i18n.t('configs.head.description'),
    image: data.context?.$i18n.t('configs.head.image'),
    siteName: data.context?.$i18n.t('configs.head.siteName'),
    ...layout.head,
  };

  switch (type) {
    case 'breadcrumb':
      jsonldData = getBreadcrumbJsonLd(data);
      break;

    case 'organization':
      jsonldData = getOrganizationJsonLd(data, headData);
      break;

    case 'offer':
      jsonldData = getOfferJsonLd(data, headData);
      break;

    case 'faq':
      if (data.isFAQ) {
        jsonldData = getFaqJsonLd(data);
      }
      break;

    case 'newsarticle':
      jsonldData = getArticleJsonLd({ ...data, image: headData.image });
      jsonldData.publisher = getOrganizationJsonLd(
        { ...layout.footer?.data?.seo, url: data.url },
        headData,
      );
      break;

    case 'webpage':
      jsonldData = {
        '@type': 'Webpage',
        name: data.title,
        description: data.description,
        breadcrumb: layout.breadcrumbs
          .map((breadcrumb) => breadcrumb.label)
          .join(' > '),
        publisher: getOrganizationJsonLd(data, headData),
      };
      break;

    case 'website':
      jsonldData = {
        '@type': 'Website',
        url: data.url,
      };
      break;

    default:
      jsonldData = {};
  }

  if (Object.keys(jsonldData).length > 0) {
    return {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        '@context': 'https://schema.org',
        ...jsonldData,
      }),
    };
  } else {
    return {};
  }
};
