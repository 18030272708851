import { productFull } from '~/utils/adapters/product';
import { stripRichTextWrapperTag } from '@/utils/html';

import {
  routerLinks,
  offerLinks,
  countryLinks,
  regionLinks,
  coverTypeLinks,
} from '~/utils/fetch-links';

export const cardsAdapter = (cards, i18n, enhancedLinkSerializer) => {
  return cards.map(({ featured_card: featuredCard }) => {
    if (!featuredCard) {
      return null;
    }
    return {
      type: 'product-card',
      data: productFull(featuredCard, i18n, enhancedLinkSerializer),
    };
  });
};

const getOrdering = (order, queryType) => {
  switch (order) {
    case 'DESC date':
      return `[my.${queryType}.date desc, document.last_publication_date desc]`;
    case 'ASC title':
    default:
      return `[my.${queryType}.title]`;
  }
};
const getItems = async (
  { lang, page, offer, cardsPerPage, orderBy, isPro, filters },
  prismic,
) => {
  const order = getOrdering(orderBy, 'product_page');

  // get product page
  const query = [prismic.filter.at('document.type', 'product_page')];
  if (offer?.id) {
    query.push(prismic.filter.at(`my.product_page.offer`, offer.id));
  }

  // handle if pro or not
  if (isPro) {
    query.push(prismic.filter.at('document.tags', ['pro']));
  } else {
    query.push(prismic.filter.not('document.tags', ['pro']));
  }

  // handle filters
  filters.forEach((filter) => {
    const { id, fieldType, value } = filter || {};

    // filter query with values
    if (value) {
      query.push(
        prismic.filter.any(`my.product_page.${id}.${fieldType}`, [
          value,
          'All',
        ]),
      );
      // handle excluded values
      if (fieldType !== 'need') {
        query.push(
          prismic.filter.not(
            `my.product_page.${id}_exclude.${fieldType}`,
            value,
          ),
        );
      }
    }
  });

  const items = await prismic.api.query(query, {
    orderings: order,
    pageSize: cardsPerPage,
    page,
    fetchLinks: [
      ...routerLinks,
      ...offerLinks,
      ...countryLinks,
      ...regionLinks,
      ...coverTypeLinks,
    ],
    lang,
  });

  return {
    totalPages: items.total_pages,
    cards: items.results.map((result) => ({
      featured_card: { ...result, link_type: 'Document' },
    })),
  };
};

const prioritizedProduct = (productsFound) => {
  const hasTwoProducts = productsFound.length === 2;
  if (hasTwoProducts) {
    const localProduct = productsFound.find(
      (product) => !product.featured_card.data.global_offer,
    );
    if (localProduct) {
      return [localProduct];
    }
  }

  return productsFound;
};

export default async (
  { primary },
  { page, i18n, prismic, enhancedLinkSerializer, isPro, filters },
) => {
  const lang = i18n.localeProperties.value.language;
  const {
    offer,
    title,
    notification_message: notificationMessage,
    with_pagination: withPagination,
    cards_per_page: cardsPerPage,
    order_by: orderBy,
  } = primary || {};

  const cardItems = await getItems(
    {
      lang,
      page,
      offer,
      cardsPerPage,
      orderBy,
      isPro,
      filters,
    },
    prismic,
  );

  if (!cardItems) {
    return null;
  }
  const { cards, totalPages } = cardItems || {};
  const prioritizedCards = prioritizedProduct(cards);

  return {
    title: null,
    fixed: true,
    withSlider: false,
    withGrid: true,
    withPagination,
    hasThreeColsGrid: true,
    totalPages,
    newProductsList: primary.new_products_list,
    type: primary.featured_block_type,
    grayBackground: true,
    withBorderTop: false,
    cards: cardsAdapter(prioritizedCards, i18n, enhancedLinkSerializer),
    listTitle: title && stripRichTextWrapperTag(title),
    notificationMessage:
      notificationMessage && stripRichTextWrapperTag(notificationMessage),
    noResultTitle: i18n.t('global.no-result-title'),
  };
};
