import { richText, stripRichTextWrapperTag } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';

export default ({ primary, items }, { i18n, enhancedLinkSerializer }) => {
  return {
    title: stripRichTextWrapperTag(primary.picto_carousel_title),
    cards: items.map(
      ({
        picto_card_icon: pictoCardIcon,
        picto_card_subtitle: pictoCardSubtitle,
        picto_card_title: pictoCardTitle,
        picto_card_text: pictoCardText,
      }) => ({
        icon: imageAdapter(pictoCardIcon),
        subtitle: richText(pictoCardSubtitle) || '',
        title: richText(pictoCardTitle),
        text: richText(pictoCardText) || '',
      }),
    ),
    primaryButton: {
      label: primary.additional_link_label || i18n.t('cta.demander-devis'),
      link: enhancedLinkSerializer(primary.additional_link),
      variant: primary.additional_link_variant || 'secondary',
    },
    secondaryButton: {
      label: primary.link_label || i18n.t('cta.souscrire'),
      link: enhancedLinkSerializer(primary.link),
      variant: primary.link_variant || 'secondary',
    },
  };
};
