<template>
  <div v-if="debug !== null">
    <slice-zone
      v-if="content?.data?.slices && content.data.slices[debug]"
      :slices="[content.data.slices[debug]]"
      :components="components"
      :context="content.data"
    />
    <div>
      Debugging slice #{{ debug }} - type:
      <code>{{ content.data.slices[debug].slice_type }}</code>
    </div>
    <pre>
      {{ content.data.slices[debug] }}
    </pre>
  </div>
  <Layout v-else>
    <slice-zone
      v-if="content?.data?.slices"
      :slices="content.data.slices"
      :components="components"
      :context="content.data"
    />
  </Layout>
</template>

<script>
import Layout from '@/components/layout';

export default {
  name: 'AprilSliceZone',
  components: {
    Layout,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    components: {
      type: Object,
      required: true,
    },
    debug: {
      type: Number,
      default: null,
    },
  },
};
</script>
