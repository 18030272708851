<template>
  <organisms-edito-carousel v-if="sliceData" v-bind="sliceData" />
</template>

<script setup>
import { getSliceComponentProps } from '@prismicio/vue';
import relatedGuidesAdapter from '@/slices/RelatedGuides/adapter';
import { useI18n, usePrismic, useNuxtApp, useAsyncData } from '#imports';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const i18n = useI18n();
const { client: $prismic } = usePrismic();
const { $enhancedLinkSerializer } = useNuxtApp();

const { data: sliceData } = await useAsyncData(
  `relatedGuides-${props.slice.id}`,
  async () => {
    return await relatedGuidesAdapter(props.slice, {
      pageData: props.context,
      i18n,
      prismic: $prismic,
      enhancedLinkSerializer: $enhancedLinkSerializer,
    });
  },
);
</script>
