import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/vercel/path0/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_1uFu2M4PVa from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.js";
import plugin_PWMUhiA9tt from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/plugin.js";
import vuex_owYp5qnaH8 from "/vercel/path0/plugins/vuex.js";
import gtm_client_KEcT5dOoKs from "/vercel/path0/plugins/gtm.client.js";
import enhanced_link_serializer_2y2YqaQDPt from "/vercel/path0/plugins/prismic/enhanced-link-serializer.js";
import prismicLinks_mnDjwvyvge from "/vercel/path0/plugins/prismic/prismicLinks.js";
import cloud_image_A9GSc7wJjl from "/vercel/path0/plugins/cloud-image.js";
import axeptio_kqwAdnYpJz from "/vercel/path0/plugins/axeptio.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_tbFNToZNim,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_client_1uFu2M4PVa,
  plugin_PWMUhiA9tt,
  vuex_owYp5qnaH8,
  gtm_client_KEcT5dOoKs,
  enhanced_link_serializer_2y2YqaQDPt,
  prismicLinks_mnDjwvyvge,
  cloud_image_A9GSc7wJjl,
  axeptio_kqwAdnYpJz
]