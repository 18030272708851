<template>
  <breadcrumbs :with-gray-background="slice.primary.with_gray_background" />
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue';

export default {
  name: 'Breadcrumbs',
  components: {
    breadcrumbs: () => import('@/components/breadcrumbs'),
  },
  props: getSliceComponentProps(['slice']),
};
</script>
