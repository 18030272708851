import { richText, stripRichTextWrapperTag } from '../../utils/html';
import { adapter as imageAdapter } from '../../components/cloud-image/utils';
import getPrice from '../../utils/get-price';
import { getEkomiData, getRating } from '@/utils/ekomi';

export const getQuickAccessData = (
  primary,
  items,
  enhancedLinkSerializer,
  i18n,
) => {
  const quickAccess = {
    withQuickAccess: primary.active_quick_access,
    quickAccessTitle: primary.quick_access_title,
    quickAccessData: items,
    quickAccessType: primary.quick_access_type,
  };
  return quickAccess.withQuickAccess
    ? quickAccessAdapter(quickAccess, {
        enhancedLinkSerializer,
        i18n,
      })
    : {};
};

const quickAccessAdapter = (
  { withQuickAccess, quickAccessTitle, quickAccessData, quickAccessType },
  { enhancedLinkSerializer, i18n },
) => ({
  withQuickAccess,
  quickAccessType,
  quickAccessData: {
    title: quickAccessTitle,
    listProducts: quickAccessData
      .filter(
        ({
          quick_access_link: quickAccessLink,
          quick_access_label: quickAccessLabel,
        }) => quickAccessLink && quickAccessLabel,
      )
      .map(
        ({
          quick_access_link: quickAccessLink,
          quick_access_label: quickAccessLabel,
          quick_access_icon: quickAccessIcon,
        }) => {
          return {
            icon: {
              ...imageAdapter(quickAccessIcon),
              alt: quickAccessLabel
                ? i18n.t('global.icon', {
                    title: quickAccessLabel.toLowerCase(),
                  })
                : null,
            },
            name: quickAccessLabel,
            link: enhancedLinkSerializer(quickAccessLink),
          };
        },
      ),
  },
});

export const sanitizeItems = (
  item,
  pageData,
  i18n,
  enhancedLinkSerializer,
  ekomi = {},
) => {
  const { type: documentType } = pageData;
  const { withEkomiRating, ekomiData, ekomiInfobulle } = ekomi;

  const title = stripRichTextWrapperTag(item.hero_push_title);
  let subtitle = richText(item.hero_push_subtitle);

  if (pageData && documentType === 'product_page') {
    const {
      starting_price: startingPrice,
      price_frequency: priceFrequency,
      legal_reference: legalReference,
      currency,
    } = pageData;

    const defaultCurrency = i18n.t('global.currency');
    const locale = i18n.localeProperties.value.code;
    const formater = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency || defaultCurrency || 'EUR',
      currencyDisplay: ['EUR', 'GBP'].includes(currency)
        ? 'narrowSymbol'
        : 'code',
    });

    if (startingPrice) {
      subtitle = `<p>${getPrice(
        formater.format(startingPrice),
        priceFrequency,
        legalReference,
        i18n,
        {
          withoutFormat: true,
        },
      )}</p>`;
    }
  }

  const imageMobile = imageAdapter(
    item.hero_push_mobile_image,
    item.hero_push_image_alt,
  );

  const imageDesktop = imageAdapter(
    item.hero_push_desktop_image,
    item.hero_push_image_alt,
  );

  const data = {
    title,
    subtitle,
    description: richText(item.hero_push_description),

    link: item.hero_push_link
      ? enhancedLinkSerializer(item.hero_push_link)
      : null,
    linkLabel: item.hero_push_link_label,
    linkLabelVariant: item.hero_push_link_variant,

    linkAdditional: item.hero_push_link_additional
      ? enhancedLinkSerializer(item.hero_push_link_additional)
      : null,
    linkLabelAdditional: item.hero_push_link_additional_label,
    linkLabelAdditionalVariant: item.hero_push_link_additional_variant,

    imageMobile: imageMobile?.filename ? imageMobile : null,
    imageDesktop: imageDesktop?.filename ? imageDesktop : null,
    rating:
      withEkomiRating && ekomiData
        ? {
            rating: ekomiData.average_rating,
            ratingLabel: `<b>${getRating(
              ekomiData.average_rating,
              i18n,
            )}/5</b> (${ekomiData.review_count} avis)`,
            infobulle: ekomiInfobulle
              ? `<button data-tooltip="${ekomiInfobulle}">i</button>`
              : null,
          }
        : null,
  };

  return data;
};

export default async (
  { primary, items },
  { pageData, i18n, enhancedLinkSerializer, prismic },
) => {
  const ekomiAccount =
    pageData.ekomi_account ?? pageData.market?.data?.ekomi_account;

  const withEkomiRating = primary.with_ekomi_rating;
  let ekomiData, ekomiInfobulle;

  if (withEkomiRating) {
    ekomiInfobulle = primary.ekomi_rating_infobulle;
    ekomiData = await getEkomiData(
      ekomiAccount,
      'reviews',
      window.$nuxt.context,
    );
  }

  const withLisaModule = primary.module_lisa?.uid;

  const heroData = sanitizeItems(
    primary,
    pageData,
    i18n,
    enhancedLinkSerializer,
    { withEkomiRating, ekomiData, ekomiInfobulle },
  );

  if (!heroData) {
    return null;
  }

  const quickAccessData = getQuickAccessData(
    primary,
    items,
    enhancedLinkSerializer,
    i18n,
  );

  return {
    narrowHero: primary.narrow_hero,
    largeHero: primary.large_hero,
    primaryColor: primary.primary_color,
    blackColor: primary.black_color,
    withOverlay: primary.with_overlay,
    heroData,
    ...quickAccessData,
    ...(withLisaModule
      ? {
          withLisaForm: true,
          isNewLisa: primary.module_lisa?.data?.new_lisa,
          lisaCTA: primary.lisa_cta,
          lisaContextAutofill: primary.lisa_context_autofill,
        }
      : {}),
  };
};
