import { LAYOUT_SET_TOP_HEADER } from '@/store/mutationTypes';

export default (slices, store) => {
  const hasTopHeader = !!slices?.find(
    (slice) => slice.slice_type === 'top_header',
  );

  if (!hasTopHeader) {
    store.commit(`layout/${LAYOUT_SET_TOP_HEADER}`, null);
  }
};
