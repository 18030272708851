<template>
  <organisms-legal-mention v-if="sliceData" v-bind="sliceData" />
</template>

<script>
import { ref } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import legalMentionsAdapter from '@/slices/LegalMentions/adapter';

export default {
  name: 'LegalMentions',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup: (props) => {
    const sliceData = ref(null);

    sliceData.value = legalMentionsAdapter(props.slice);

    return { sliceData };
  },
};
</script>
