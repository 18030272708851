import { stripRichTextWrapperTag } from '@/utils/html';
import { pushLisaForm } from '@/plugins/gtm.client';
import { generateFormData } from '~/slices/LisaContent/adapter';
import translations from '@/mixins/componentTranslations.js';
import backupData from '@/public/data/lisa.json';
import { useI18n, navigateTo } from '#imports';

export default {
  mixins: [translations],
  data() {
    return {
      lang: null,
      sliceData: null,
      lisaData: null,
      heroLisaData: null,
      heroLisaDefaultData: null,
      residence: null,
      destination: null,
      profile: null,
      product: null,
      funnel: null,
      nationality: null,
      nationalityNUllId: null,
      submitted: false,
      form: null,
      trackingData: {
        residence: null,
        destination: null,
        profile: null,
        nationality: null,
      },
    };
  },
  async created() {
    const i18n = useI18n();
    this.lang = i18n.localeProperties.value.language;

    const temporaryTitle = [
      {
        type: 'paragraph',
        text: 'Find the right insurance for you:',
        spans: [
          {
            type: 'label',
            data: {
              label: 'black-color',
            },
          },
        ],
      },
    ];

    await this.fetchTranslations('lisa', i18n); // TODO: to probably put back
    this.heroLisaDefaultData = {
      formData: generateFormData(i18n),
      title: stripRichTextWrapperTag(temporaryTitle),
    };
  },
  methods: {
    redirect(path) {
      const isPathExternal = path.includes('http');

      if (isPathExternal) {
        window.location.href = path;
      } else {
        navigateTo({ path });
      }
    },
    onFormLisaSubmit(data) {
      // Tracking
      const resultType =
        data?.type === 'quote' ? 'quote engine' : 'product page';
      const { residence, destination, profile, nationality } =
        this.trackingData || {};
      pushLisaForm(
        { store: this.$store, $gtm: this.$gtm },
        {
          lisaPaysResidence: residence,
          lisaPaysDestination: destination,
          lisaBesoin: profile,
          lisaPaysNationality: nationality,
          resultType,
        },
      );

      const isSubmitFunnelBtn = data?.type === 'quote';

      // Redirect
      if (this.product && !isSubmitFunnelBtn) {
        this.redirect(this.product.fields[`url_${this.lang}`]);
        this.product = null;
      }

      if (this.funnel && isSubmitFunnelBtn) {
        this.redirect(this.funnel.fields[`url_${this.lang}`]);
        this.funnel = null;
      }

      // Reset lisa module
      for (
        let index = 0;
        index < this.sliceData.formLisaData.formData.inputs.length;
        index++
      ) {
        this.resetInput(index, index !== 0, index !== 0);
      }
      // Desactivate lisa panel buttons
      if (this.sliceData.submitCta) this.sliceData.submitCta.disabled = true;
      if (this.sliceData.cta) this.sliceData.cta.disabled = true;
    },
    onFormLisaInput(inputData) {
      this.form = inputData.form;

      // reset form result on input change
      this.product = null;
      this.funnel = null;

      // desactivate buttons on input change
      if (this.sliceData.cta) {
        this.sliceData.cta.disabled = true;
      }
      if (this.sliceData.submitCta) this.sliceData.submitCta.disabled = true;
      if (this.sliceData.formLisaData.formData.buttons?.length)
        this.sliceData.formLisaData.formData.buttons[0].cta.disabled = true;

      if (inputData && inputData.input) {
        if (inputData.input.name === this.$t('name.residence')) {
          this.onInputResidence(inputData);
        }

        if (inputData.input.name === this.$t('name.destination')) {
          this.onInputDestination(inputData);
        }

        if (inputData.input.name === this.$t('name.profile')) {
          this.onInputProfile(inputData);
        }

        if (inputData.input.name === this.$t('name.nationality')) {
          this.onInputNationality(inputData);
        }
      }
    },
    onInputResidence(inputData) {
      this.residence = inputData.input.value;
      this.trackingData.residence = this.getIsoFromId(
        this.residence,
        this.lisaData.countries,
      );
      this.resetAllFieldsAfter(0);
      this.setNationalityFieldActive(false);

      const residenceCountriesLists = this.getCountriesLists(this.residence);

      const flowsLists = this.getFlowsListsFrom(residenceCountriesLists);

      let destinationCountries = flowsLists.map((flow) => {
        let destinations = flow.fields.destination;

        destinations = destinations
          .map(
            (listId) =>
              this.lisaData?.countriesLists.find((list) => list.id === listId)
                .fields.countries,
          )
          .filter((list) => list !== undefined);

        return destinations;
      });
      destinationCountries = this.flatten(destinationCountries, 2).sort(
        this.sortAlphabetically,
      );

      const uniqDestinationCountries = [
        ...new Map(
          destinationCountries.map((item) => [item.id, item]),
        ).values(),
      ];

      if (uniqDestinationCountries.length > 0) {
        this.setInput(1, uniqDestinationCountries);
      }
    },
    onInputDestination(inputData) {
      this.destination = inputData.input.value;
      this.trackingData.destination = this.getIsoFromId(
        this.destination,
        this.lisaData.countries,
      );
      this.resetAllFieldsAfter(1);
      this.setNationalityFieldActive(false);

      const residenceCountriesLists = this.getCountriesLists(this.residence);

      const destinationCountriesLists = this.getCountriesLists(
        this.destination,
      );

      let flowsLists = this.getFlowsListsFrom(residenceCountriesLists);

      flowsLists = flowsLists.filter((flow) =>
        destinationCountriesLists.some((destination) =>
          flow.fields.destination?.includes(destination.id),
        ),
      );

      const profilesList = this.flatten(
        flowsLists.map((flow) => flow.fields.profile),
      ).map((profileId) =>
        this.lisaData?.profiles.filter((profile) => profile.id === profileId),
      );

      const orderedProfilesList = this.flatten(profilesList).sort(
        this.sortByOrder,
      );

      if (orderedProfilesList.length > 0) {
        this.setInput(2, [...new Set(orderedProfilesList)]);
      }
    },
    onInputProfile(inputData) {
      this.profile = inputData.input.value;
      this.trackingData.profile = this.getValueFromId(
        this.profile,
        this.lisaData.profiles,
      );

      this.resetAllFieldsAfter(2);

      const residenceCountriesLists = this.getCountriesLists(this.residence);

      const destinationCountriesLists = this.getCountriesLists(
        this.destination,
      );

      const flows = this.getFlowsListsFrom(residenceCountriesLists)
        .filter((flow) =>
          destinationCountriesLists.some((destination) =>
            flow.fields.destination?.includes(destination.id),
          ),
        )
        .filter((flow) => flow.fields.profile.includes(this.profile));

      let nationalitiesLists = this.flatten(
        flows.map((flow) => flow.fields.nationality),
      );
      if (
        flows.length > 1 &&
        !nationalitiesLists.includes(this.nationalityNUllId)
      ) {
        nationalitiesLists = this.flatten(
          nationalitiesLists
            .map((listId) =>
              this.lisaData?.countriesLists.find(({ id }) => listId === id),
            )
            .map((list) => list.fields.countries)
            .filter((list) => list !== undefined),
        ).sort(this.sortAlphabetically);

        this.setInput(3, [...new Set(nationalitiesLists)]);
        this.setNationalityFieldActive(true);
      } else {
        this.product =
          flows.length &&
          this.lisaData.products.find((product) =>
            flows[0].fields.product.includes(product.id),
          );

        this.funnel =
          flows.length &&
          this.lisaData.funnels.find((funnel) =>
            flows[0].fields.funnel?.includes(funnel.id),
          );

        if (this.funnel && this.sliceData.cta) {
          this.sliceData.cta.disabled = false;
        }

        if (this.sliceData.submitCta)
          this.sliceData.submitCta.disabled = !(
            this.residence &&
            this.destination &&
            this.profile
          );

        if (this.sliceData.formLisaData.formData.buttons)
          this.sliceData.formLisaData.formData.buttons[0].cta.disabled = !(
            this.residence &&
            this.destination &&
            this.profile
          );
      }
    },
    onInputNationality(inputData) {
      this.nationality = inputData.input.value;
      this.trackingData.nationality = this.getIsoFromId(
        this.nationality,
        this.lisaData.countries,
      );

      const residenceCountriesLists = this.getCountriesLists(this.residence);

      const destinationCountriesLists = this.getCountriesLists(
        this.destination,
      );

      const nationalitiesLists = this.getCountriesLists(this.nationality);

      const flow = this.getFlowsListsFrom(residenceCountriesLists)
        .filter((flow) =>
          destinationCountriesLists.some((destination) =>
            flow.fields.destination?.includes(destination.id),
          ),
        )
        .filter((flow) =>
          nationalitiesLists.some((nationality) =>
            flow.fields.nationality?.includes(nationality.id),
          ),
        )
        .find((flow) => flow.fields.profile.includes(this.profile));

      this.product =
        flow &&
        this.lisaData.products.find((product) =>
          flow.fields.product.includes(product.id),
        );

      this.funnel =
        flow &&
        this.lisaData.funnels.find((funnel) =>
          flow.fields.funnel?.includes(funnel.id),
        );

      if (this.funnel && this.sliceData.cta) {
        this.sliceData.cta.href = this.funnel.fields[`url_${this.lang}`];
        this.sliceData.cta.disabled = false;

        if (this.funnel.fields[`url_${this.lang}`].includes('http')) {
          this.sliceData.cta.external = '';
        }
      }

      if (this.sliceData.submitCta)
        this.sliceData.submitCta.disabled = !(
          this.residence &&
          this.destination &&
          this.profile &&
          this.nationality
        );

      const buttons = this.sliceData.formLisaData?.formData?.buttons;
      if (buttons?.length)
        buttons[0].cta.disabled = !(
          this.residence &&
          this.destination &&
          this.profile &&
          this.nationality
        );
    },
    getCountriesLists(countryId) {
      return this.lisaData?.countriesLists.filter((list) =>
        list.fields?.countries?.find(({ id }) => id === countryId),
      );
    },
    getFlowsListsFrom(countriesLists) {
      const flowsListsId = this.lisaData
        ? Object.keys(this.lisaData?.lisa)
        : [];
      const flowLists = [];

      countriesLists?.forEach(({ id }) => {
        flowsListsId.forEach((flowId) => {
          if (flowId.includes(id)) flowLists.push(flowId);
        });
      });

      return this.flatten([...flowLists.map((id) => this.lisaData?.lisa[id])]);
    },
    getValueFromId(id, array) {
      return array.find((item) => item.id === id)?.fields[this.lang];
    },
    getIsoFromId(id, array) {
      return array.find((item) => item.id === id)?.fields.language;
    },
    setNationalityFieldActive(active) {
      this.sliceData.formLisaData.formData.inputs[3].field.hidden = !active;
    },
    setInput(inputIndex, data) {
      // activate field
      this.sliceData.formLisaData.formData.inputs[inputIndex].field.disabled =
        false;
      this.sliceData.formLisaData.formData.inputs[
        inputIndex
      ].field.resetButton = true;

      // populate field with data options
      this.sliceData.formLisaData.formData.inputs[inputIndex].field.options =
        data.map(({ id, fields }) => ({
          label: fields[this.lang],
          value: id,
        }));
    },
    resetAllFieldsAfter(inputIndex) {
      for (
        let index = inputIndex + 1;
        index < this.sliceData.formLisaData.formData.inputs.length;
        index++
      ) {
        this.resetInput(index);
      }
    },
    resetInput(inputIndex, doEmptyOptions = true, doDisableField = true) {
      // empty options
      if (doEmptyOptions) this.setInput(inputIndex, []);

      // reset form data, slice data states, tracking data
      const name = this.sliceData.formLisaData.formData.inputs[inputIndex].name;
      this.form[name] = '';
      this[name] = null;
      this.sliceData.formLisaData.formData.inputs[inputIndex].value = '';

      // reset css styles
      const inputLabel = this.$refs.lisa.$el.querySelector(
        `.form-element:nth-of-type(${inputIndex + 1}) .input-label-content`,
      );
      if (inputLabel) inputLabel.classList.remove('-filled', '-select');

      // disable field
      if (doDisableField) {
        this.sliceData.formLisaData.formData.inputs[inputIndex].field.disabled =
          true;
        this.sliceData.formLisaData.formData.inputs[
          inputIndex
        ].field.resetButton = false;
      }
    },
    setLisaData() {
      this.lisaData = backupData;
      this.nationalityNUllId = backupData?.countriesLists.find(
        (countriesList) => countriesList.fields.Name === 'NULL',
      ).id;
    },
    flatten(arr, depth = 1) {
      return arr.reduce(
        (a, v) =>
          a.concat(
            depth > 1 && Array.isArray(v) ? this.flatten(v, depth - 1) : v,
          ),
        [],
      );
    },
    sortAlphabetically(a, b) {
      if (a && b && a.fields && b.fields)
        return a.fields[this.lang]?.localeCompare(b.fields[this.lang]);
      else return a?.fields?.Name.localeCompare(b?.fields?.Name);
    },
    sortByOrder(a, b) {
      if (a && b && a.fields && b.fields)
        return a.fields.order - b.fields.order;
    },
  },
};
