import { countries, getFlagEmoji } from '~/utils/helpers/get-countries.js';

const defaultData = {
  value: '',
  required: true,
};

export const getInputs = ({ lang, translations: i18n }) => {
  const countriesOptions = Object.keys(countries).map((countryKey) => ({
    label: countries[countryKey].locales[lang],
    value: countryKey,
  }));

  const phoneOptions = Object.keys(countries)
    .filter((countryKey) => !!countries[countryKey].dialCode)
    .map((countryKey) => ({
      label: `${countries[countryKey].locales[lang]} (${
        countries[countryKey].dialCode
      }) ${getFlagEmoji(countryKey)}`,
      value: countries[countryKey].dialCode,
      countryCode: countryKey,
    }));

  phoneOptions.unshift({ label: '---', disabled: true });

  return {
    // Base fields
    'First name': {
      name: 'firstname',
      validator: 'text',
      ...defaultData,
      field: {
        id: 'firstName',
        label: i18n.entries.firstName.label,
      },
      messages: i18n.entries.firstName.messages,
    },
    'Last name': {
      name: 'lastname',
      validator: 'text',
      ...defaultData,
      field: {
        id: 'lastName',
        label: i18n.entries.lastName.label,
      },
      messages: i18n.entries.lastName.messages,
    },
    Email: {
      name: 'email',
      ...defaultData,
      validator: 'email',
      field: {
        id: 'email',
        label: i18n.entries.email.label,
        type: 'email',
      },
      messages: i18n.entries.email.messages,
    },
    Phone: {
      name: 'double-input',
      field: {
        type: 'double-input',
        inputs: [
          {
            name: 'country-code',
            value: '',
            required: true,
            field: {
              id: 'country-code',
              label: i18n.entries.phoneIndicator.label,
              type: 'select-cc',
              options: phoneOptions,
            },
            messages: i18n.entries.phoneIndicator.messages,
          },
          {
            name: 'phone-cc',
            value: '',
            validator: 'internationalPhone2',
            required: true,
            field: {
              id: 'phone-cc',
              label: i18n.entries.phone.label,
              type: 'tel',
              minlength: 1,
              maxLength: 15,
              mask: {
                mask: '0000000000000',
              },
            },
            messages: i18n.entries.phone.messages,
          },
        ],
      },
    },
    Message: {
      name: 'description',
      ...defaultData,
      validator: 'textarea',
      field: {
        id: 'message',
        label: i18n.entries.message.label,
        type: 'textarea',
      },
      messages: i18n.entries.message.messages,
    },
    // Object fields
    'Object - Individual': {
      name: 'object',
      ...defaultData,
      field: {
        id: 'objectIndividual',
        label: i18n.entries.objectIndividual.label,
        type: 'select',
        options: i18n.entries.objectIndividual.options,
      },
      messages: i18n.entries.objectIndividual.messages,
    },
    'Object - Company': {
      name: 'object',
      ...defaultData,
      field: {
        id: 'objectCompany',
        label: i18n.entries.objectCompany.label,
        type: 'select',
        options: i18n.entries.objectCompany.options,
      },
      messages: i18n.entries.objectCompany.messages,
    },
    'Object - School': {
      name: 'object',
      ...defaultData,
      field: {
        id: 'objectSchool',
        label: i18n.entries.objectSchool.label,
        type: 'select',
        options: i18n.entries.objectSchool.options,
      },
      messages: i18n.entries.objectSchool.messages,
    },
    'Object - Broker - France/Monaco': {
      name: 'object',
      ...defaultData,
      field: {
        id: 'objectBrokerFranceMonaco',
        label: i18n.entries.objectBrokerFranceMonaco.label,
        type: 'select',
        options: i18n.entries.objectBrokerFranceMonaco.options,
      },
      messages: i18n.entries.objectBrokerFranceMonaco.messages,
    },
    'Object - Broker - Other': {
      name: 'object',
      ...defaultData,
      field: {
        id: 'objectBrokerOtherEntity',
        label: i18n.entries.objectBrokerOtherEntity.label,
        type: 'select',
        options: i18n.entries.objectBrokerOtherEntity.options,
      },
      messages: i18n.entries.objectBrokerOtherEntity.messages,
    },
    'Object - Health': {
      name: 'object',
      ...defaultData,
      field: {
        id: 'objectProvider',
        label: i18n.entries.objectProvider.label,
        type: 'select',
        options: i18n.entries.objectProvider.options,
      },
      messages: i18n.entries.objectProvider.messages,
    },
    // Routing fields
    'Contract number': {
      name: 'policynumber',
      ...defaultData,
      field: {
        id: 'contractNumber',
        label: i18n.entries.contractNumber.label,
        type: 'text',
        helper: i18n.entries.member.helper,
      },
      messages: i18n.entries.contractNumber.messages,
    },
    'Residence country': {
      name: 'residencecountry',
      ...defaultData,
      field: {
        id: 'residenceCountry',
        label: i18n.entries.residenceCountry.label,
        type: 'select',
        options: countriesOptions,
      },
      messages: i18n.entries.residenceCountry.messages,
    },
    'Coverage country': {
      name: 'destinationcountry',
      ...defaultData,
      field: {
        id: 'coverageCountry',
        label: i18n.entries.coverageCountry.label,
        type: 'select',
        options: countriesOptions,
      },
      messages: i18n.entries.coverageCountry.messages,
    },
    'Company name': {
      name: 'name',
      ...defaultData,
      field: {
        id: 'companyName',
        label: i18n.entries.companyName.label,
        type: 'text',
      },
      messages: i18n.entries.companyName.messages,
    },
    'Company address': {
      name: 'location',
      ...defaultData,
      field: {
        id: 'companyAddress',
        label: i18n.entries.companyAddress.label,
        type: 'select',
        options: countriesOptions,
      },
      messages: i18n.entries.companyAddress.messages,
    },
    'School name': {
      name: 'name',
      ...defaultData,
      field: {
        id: 'schoolName',
        label: i18n.entries.schoolName.label,
        type: 'text',
      },
      messages: i18n.entries.schoolName.messages,
    },
    'School address': {
      name: 'location',
      ...defaultData,
      field: {
        id: 'schoolAddress',
        label: i18n.entries.schoolAddress.label,
        type: 'select',
        options: countriesOptions,
      },
      messages: i18n.entries.schoolAddress.messages,
    },
    'Practice name': {
      name: 'name',
      ...defaultData,
      field: {
        id: 'practiceName',
        label: i18n.entries.practiceName.label,
        type: 'text',
      },
      messages: i18n.entries.practiceName.messages,
    },
    'Practice address': {
      name: 'practiceAddress',
      ...defaultData,
      field: {
        id: 'practiceAddress',
        label: i18n.entries.practiceAddress.label,
        type: 'text',
      },
      messages: i18n.entries.practiceAddress.messages,
    },
    'Structure name': {
      name: 'name',
      ...defaultData,
      field: {
        id: 'structureName',
        label: i18n.entries.structureName.label,
        type: 'text',
      },
      messages: i18n.entries.structureName.messages,
    },
    'Structure address': {
      name: 'structureAddress',
      ...defaultData,
      field: {
        id: 'structureAddress',
        label: i18n.entries.structureAddress.label,
        type: 'select',
        options: countriesOptions,
      },
      messages: i18n.entries.structureAddress.messages,
    },
    Nationality: {
      name: 'nationality',
      ...defaultData,
      field: {
        id: 'nationality',
        label: i18n.entries.nationality.label,
        type: 'select',
        options: countriesOptions,
      },
      messages: i18n.entries.nationality.messages,
    },
    'Who is referring you': {
      name: 'referral',
      ...defaultData,
      field: {
        id: 'referrer',
        label: i18n.entries.referrer.label,
        type: 'text',
      },
      messages: i18n.entries.referrer.messages,
    },
    'Web address': {
      name: 'websiteurl',
      ...defaultData,
      field: {
        id: 'website',
        label: i18n.entries.website.label,
        type: 'text',
      },
      messages: i18n.entries.website.messages,
    },
    'Client termination': {
      name: 'aprilentity',
      ...defaultData,
      field: {
        id: 'clientTermination',
        label: i18n.entries.clientTermination.label,
        type: 'select',
        options: i18n.entries.clientTermination.options,
      },
      messages: i18n.entries.clientTermination.messages,
    },
  };
};
